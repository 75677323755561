<template>
  <div style="width: 100%;">
    <v-container class="mainContainer" fluid>
      <v-row>
        <v-col class='col-md-3 d-flex flex-column align-start justify-start'>
          <div class="d-flex flex-row align-center">
            <v-btn fab x-small @click="()=>this.$router.go(-1)">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <h2 class="mx-1" style="color: maroon;">Order #{{this.$route.params.id}}</h2>
            <span v-if="!loader">
              <span v-if="invoice.status===-1" class="pa-1 v-btn warning" style="font-size: 12px">{{parseOrderStatus(invoice.status)}}</span>
              <span v-if="invoice.status===100" class="pa-1 v-btn red" style="font-size: 12px">Draft (Unsaved)</span>
              <span v-if="invoice.status===0" class="pa-1 v-btn orange" style="font-size: 12px">{{parseOrderStatus(invoice.status)}}</span>
              <span v-if="invoice.status===1" class="pa-1 v-btn success" style="font-size: 12px">{{parseOrderStatus(invoice.status)}}</span>
              <span v-if="invoice.status===2" class="pa-1 v-btn success" style="font-size: 12px">{{parseOrderStatus(invoice.status)}}</span>
              <span v-if="invoice.status===3" class="pa-1 v-btn success" style="font-size: 12px">{{parseOrderStatus(invoice.status)}}</span>
              <span v-if="invoice.status===4" class="pa-1 v-btn success" style="font-size: 12px">{{parseOrderStatus(invoice.status)}}</span>
              <span v-if="invoice.status===5" class="pa-1 v-btn success" style="font-size: 12px">{{parseOrderStatus(invoice.status)}}</span>
              <span v-if="invoice.status===6" class="pa-1 v-btn success" style="font-size: 12px">{{parseOrderStatus(invoice.status)}}</span>
            </span>
            <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
            ></v-progress-circular>
            <v-progress-circular
              indeterminate
              color="green"
              v-if="initialLoader"
            ></v-progress-circular>
            <v-icon v-if="this.syncStatus===0" class="ml-2" color="warning">mdi-cloud-refresh</v-icon>
            <v-icon v-if="this.syncStatus===1" class="ml-2" color="success">mdi-cloud-check</v-icon>
            <v-icon v-if="this.syncStatus===2" class="ml-2" color="error">mdi-cloud-alert</v-icon>
          </div>
          <div v-if="!initialLoader" outlined class="d-flex flex-column pa-1">
            <v-card outlined>
              <v-card-text class="d-flex flex-column">
                <span v-if='this.invoice && this.invoice.createdBy'> <b>Created: </b>{{this.lookupUsername(this.invoice.createdBy)}} <span v-if="(this.invoice && this.invoice.createdAt)"> at {{utils.formatDate(invoice.createdAt)}} {{utils.formatTime(invoice.createdAt, 'long')}}</span></span>
                <span v-if="this.invoice.sealedBy && this.invoice.sealedAt"><b>Sealed: </b>{{this.lookupUsername(this.invoice.sealedBy) + ' at '+ utils.formatDate(this.invoice.sealedAt) + ' ' + utils.formatTime(this.invoice.sealedAt)}}</span>
                <span v-if="this.invoice && this.invoice.status ===-1"><b>Voided: </b>{{this.invoice.voidedBy?this.lookupUsername(this.invoice.voidedBy) + ' at '+ utils.formatDate(this.invoice.voidedAt) + ' ' + utils.formatTime(this.invoice.voidedAt):"Not Sealed"}}</span>
                <span v-if="this.invoice && this.invoice.Returns && this.invoice.Returns?.length>0">
                  <b>Returns: </b>
                  <span v-for="(r,index) in this.invoice.Returns" :key="index">
                    <router-link class="ml-2" :to="'/returns/view/'+r.id">#{{ r.id }}</router-link>
                  </span>
                </span>
              </v-card-text>
            </v-card>
            <v-card outlined>
              <v-card-text>
                <v-btn :disabled="!this.invoiceOK() || this.invoice.status !== 0 || loader"  dense color="info" small @click="attemptConfirmSeal">Seal</v-btn>
                <confirmedActionButton
                  class="mx-2" 
                  color="warning" 
                  small  
                  buttonText="Void"
                  requireUsername
                  requirePassword
                  :loading="voidLoader"
                  @cb="voidOrder"
                  fabIcon="mdi-close"
                  :disabled="this.invoice.status == -1 || loader"
                  :dialogText="'Voiding cannot be undone. Are you sure you wish to proceed?'"
                />
                <v-btn v-if="isAllowed('return', 'c')" :disabled="this.invoice.status<1" class="mr-2 white--text" dense color="teal" small @click="attemptCreateReturn">Make Return</v-btn>
              </v-card-text>
            </v-card>
            <!-- <v-card outlined>
              <v-card-text>
                <v-btn v-if="getGlobalValue('PRINT_SYSTEM_ALLOW_CLOUD_PRINT')==='true'" :disabled="this.invoice.status<1" class="mr-2 mb-2" fab x-small color="info" @click="openCloudPrintDialog()"><v-icon>mdi-cloud-print</v-icon></v-btn>
                <v-btn v-if="(getGlobalValue('PRINT_SYSTEM_ALLOW_DIRECT_PRINT')==='true')&&(getGlobalValue('PRINT_SYSTEM_ALLOW_BIG_INVOICES')==='true')" :disabled="this.invoice.status<1" class="mr-2 mb-2"  small color="info" :loading="directBigPrintLoading" @click="directPrint({id:$route.params.id})">PRINT BIG</v-btn>
                <v-btn v-if="(getGlobalValue('VEC_ALLOW_QUOTATIONS')==='true')" :disabled="this.invoice.status!=0" class="mr-2 mb-2" small color="info" :loading="quotationPrintLoading" @click="directPrintQuotation({id:$route.params.id})">PRINT QUOTATION</v-btn>
                <v-btn v-if="(getGlobalValue('PRINT_SYSTEM_ALLOW_DIRECT_PRINT')==='true')&&(getGlobalValue('PRINT_SYSTEM_ALLOW_SMALL_INVOICES')==='true')" :disabled="this.invoice.status<1" class="mr-2 mb-2"  small color="info" :loading="directSmallPrintLoading" @click="directPrint({id:$route.params.id})">PRINT SMALL</v-btn>
                <v-btn v-if="(getGlobalValue('PRINT_SYSTEM_ALLOW_DIRECT_PRINT')==='true')&&(getGlobalValue('PRINT_SYSTEM_ALLOW_BIG_INVOICES')==='true')" :disabled="this.invoice.status<1" class="mr-2 mb-2"  small color="info" :loading="directStatementPrintLoading" @click="directPrintStatement({id:$route.params.id})">PRINT STATEMENT</v-btn>
                <v-btn :loading="printPreviewDialog.loading"  :disabled="this.inoice.status<1" class="ml-2" fab x-small color="info" @click="printPreview('invoice', {id:$route.params.id})"><v-icon>mdi-printer-eye</v-icon></v-btn>
              </v-card-text>
            </v-card> -->
            <div v-if="getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_ORDER_MAIN')" style="width: 100%;">
              <dynamicButtonContainer :containerId="`${getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_ORDER_MAIN')}`" :data="{status: invoice.status, id1: $route.params.id}" style="width: 100%;"/>
            </div>
          </div>
        </v-col>
        <v-col class="col-md-9">
          <v-card outlined>
            <v-container fluid>
              <v-row>
                <v-col cols="3">
                  <span class="mr-3 d-flex flex-row">
                    <h3 class="mr-2" style="text-align: left;">Customer</h3>
                    <v-btn class="mr-2" :disabled="this.modifyDisabledCriteria" ref="addCustomerButton" v-if="!this.modifyDisabledCriteria" @click="openCreateCustomerDialog" fab color="info" x-small><v-icon>mdi-plus</v-icon></v-btn>
                    <!-- <v-btn :disabled="this.modifyDisabledCriteria" v-if="!this.modifyDisabledCriteria" @click="customersDialog=true" style="margin-left: 10px;" fab color="info" x-small><v-icon>mdi-account-search</v-icon></v-btn> -->
                    <v-btn class="mr-2" v-if="this.selectedCustomer" @click="$router.push({path: `/customers/view/${invoice.customerId}`})" fab x-small color="warning"><v-icon>mdi-account-eye-outline</v-icon></v-btn>
                    <v-btn class="mr-2" :disabled="this.modifyDisabledCriteria" @click="removeCustomerFromInvoice()" fab x-small color="error"><v-icon>mdi-close</v-icon></v-btn>
                  </span>
                  <span v-if="invoice.customerId && selectedCustomer" class="d-flex flex-row justify-start align-center">
                    <span class="d-flex flex-column mr-2">
                      <h4 style="text-align: left;">{{selectedCustomer.name}} [ID: {{ selectedCustomer.id }}]</h4>
                      <span v-if="selectedCustomer.phone"><v-icon class="mr-2">mdi-phone</v-icon>{{selectedCustomer.phone}}</span>
                      <span v-if="selectedCustomer.email"><v-icon class="mr-2">mdi-email</v-icon>{{selectedCustomer.email}}</span>
                      <span v-if="selectedCustomer.addresses && selectedCustomer.addresses.length>0" class="d-flex flex-column">
                        <div class="d-flex flex-row">
                          <v-icon class="mr-2">mdi-map-marker</v-icon> 
                          <span class="d-flex flex-column">
                            <div style="font-size: 12px;">{{selectedCustomer.addresses[0].line1}}</div>
                            <div style="font-size: 12px;">{{selectedCustomer.addresses[0].line2}}</div>
                            <div style="font-size: 12px;">
                              <span>{{selectedCustomer.addresses[0].city}}</span>
                              <span v-if="selectedCustomer.addresses[0].country">, {{selectedCustomer.addresses[0].country}}</span>
                            </div>
                          </span>
                        </div>
                      </span>
                      <span v-if="(selectedCustomer.metadata && selectedCustomer.metadata.comments) && this.getGlobalValue('VEC_ALLOW_CUSTOMER_COMMENTS')=='true'" class="d-flex flex-column">
                        <div class="d-flex flex-row justify-space-between pa-2">
                          <v-text-field class="mt-2 mr-2" :disabled="!customerCommentTextField.isEditable" :loading="customerLoading" style="width: 100%;" clearable v-model="customerCommentTextField.value" persistent-hint :hint="customerCommentTextField.createdAt?utils.formatDate(customerCommentTextField.createdAt, 'withTime')+' by '+ lookupUsername(customerCommentTextField.createdBy):''" label="Customer Comment" dense outlined></v-text-field>
                          <span class="d-flex flex-column">
                            <span class="d-flex flex-row">
                              <v-btn class="mr-2" v-if="!customerCommentTextField.isEditable" :disabled="customerCommentTextField.isEditable" icon x-small color="warning" @click="makeCustomerCommentEditable" ><v-icon>mdi-pencil</v-icon></v-btn>
                              <v-btn class="mr-2" v-if="customerCommentTextField.isEditable" :disabled="!customerCommentTextField.isEditable" icon x-small color="success" @click="addCustomerComment(customerCommentTextField.value)" ><v-icon>mdi-content-save</v-icon></v-btn>
                              <v-btn class="mr-2" v-if="customerCommentTextField.isEditable" :disabled="!customerCommentTextField.isEditable" icon x-small color="error" @click="cancelCustomerCommentEditable"><v-icon>mdi-close</v-icon></v-btn>
                            </span>                            
                            <v-btn class="mr-2" v-if="selectedCustomer.metadata.comments.length > 1" icon x-small color="warning" @click="customerCommentHistoryDialog = true" ><v-icon>mdi-history</v-icon></v-btn>                                                      
                          </span>
                        </div>
                      </span>
                    </span>
                  </span>
                </v-col>
                <v-col cols="2" v-if="invoice.customerId && getGlobalValue('VEC_ADMIN_DISPLAY_ORDER_TYPE')=='true'" class="d-flex">
                  <span class="d-flex flex-column">
                    <h3 class="mr-2" style="text-align: left;">Order Type</h3>
                    <span style="margin-top: -20px;">
                      <v-radio-group mandatory :disabled="this.modifyDisabledCriteria" @change="this.updateInvoice" v-model="invoice.deliveryInfo.deliveryType" row>
                        <v-radio
                            label="Pick Up"
                            value="pickup"
                        ></v-radio>
                        <v-radio
                            label="Delivery"
                            value="delivery"
                        ></v-radio>
                      </v-radio-group>
                      <v-text-field v-if="invoice.deliveryInfo.deliveryType == 'delivery'" :disabled="this.modifyDisabledCriteria" dense v-model="invoice.deliveryInfo.cost" @change="this.updateInvoice" min="0" label="Delivery Charges" type="number" outlined></v-text-field>
                    </span>
                  </span>
                  <!-- <span class="d-flex flex-column" v-if="invoice.status>=1 && invoice.deliveryInfo.deliveryType==='delivery'">
                    <h3>Delivery Schedule</h3>
                    <v-btn :disabled="invoice.status < 1" x-small fab class="ml-3" color="info" @click="createDeliveryA()"><v-icon>mdi-plus</v-icon></v-btn>
                    <v-data-table
                      v-if="!initialLoader"
                      :headers="deliverySchedule.tableHeaders"
                      :items="deliverySchedule.data|| []"
                      :items-per-page="-1"
                      @click:row="rowClick"
                    >
                      <template v-slot:item.dueDate="{ item }">
                        <span>{{utils.formatDate(item.dueDate) }}</span>
                      </template>
                      <template v-slot:item.status="{ item }">
                        <span>{{parseDeliveryStatus(item.status) }}</span>
                      </template>
                      <template v-slot:item.numUnits="{ item }">
                        <span>{{ item.deliveryItems?item.deliveryItems.reduce((acc,x)=>acc+x.deliveryQty,0):0 }}</span>
                      </template>
                    </v-data-table>
                  </span> -->
                </v-col>
                <v-col>
                  <span v-if="this.invoice.deliveryInfo.deliveryType == 'delivery'">
                    <span class="d-flex flex-column">
                      <span class="d-flex flex-row mb-1">
                        <h3>Delivery Address</h3>
                        <v-btn :disabled="this.modifyDisabledCriteria" v-if="selectedCustomer" color="info" class="ml-2" x-small fab @click="addressDialog=true"><v-icon>mdi-chevron-right</v-icon></v-btn>
                      </span>
                      <v-text-field dense style="margin-bottom: -20px;" :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.address.line1" @change="checkCustomAddress" label="Address Line 1" outlined></v-text-field>
                      <v-text-field dense style="margin-bottom: -20px;" :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.address.line2" @change="checkCustomAddress" label="Address Line 2" outlined></v-text-field>
                      <v-text-field dense style="margin-bottom: -20px;" :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.address.city" @change="checkCustomAddress" label="Town/City" outlined></v-text-field>
                      <v-text-field dense style="margin-bottom: -20px;" :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.address.country" @change="checkCustomAddress" label="Country" outlined></v-text-field>
                      <v-btn style="margin-top: -20px;" v-if="invoice.deliveryInfo.customAddress" color="info" @click="saveCustomAddressDialog=true" small>Save New Address</v-btn>
                    </span>
                  </span>
                </v-col>
                <v-col class="d-flex flex-column">
                  <span class="d-flex flex-column align-start">
                    <h3>Payment Method</h3>
                    <span>
                      <v-radio-group dense class="mt-0" :disabled="this.modifyDisabledCriteria" v-model="invoice.metadata.paymentMethod" @change="this.updateInvoice">
                        <v-radio
                            v-for="x in this.paymentMethods"
                            :key="x.id"
                            :label="x.name"
                            :value="x.id"
                        ></v-radio>
                      </v-radio-group>
                    </span>
                    <v-text-field
                        outlined
                        dense
                        type="number"
                        v-if="this.getGlobalValue('VEC_ORDER_INCLUDES_HP_DOWNPAYMENT_VALUE')&&invoice.metadata.paymentMethod===2"
                        name="name"
                        label="HP Downpayment"
                        @change="updateInvoice()"
                        v-model="invoice.metadata.hpDownpayment"
                    ></v-text-field>
                  </span>
                </v-col>
                <v-col class="d-flex flex-column">
                  <v-text-field :disabled="this.modifyDisabledCriteria" class="mt-3" label="Paper Bill Number" dense outlined v-model="invoice.metadata.paperRefId" @change="updateInvoice"/>
                  <div>
                    <v-textarea
                      name="input-7-1"
                      filled
                      label="Order Notes"
                      outlined
                      v-model="invoice.metadata.note"
                      :disabled="this.modifyDisabledCriteria"
                      @change="updateInvoice"
                    ></v-textarea>
                    <v-btn block v-if="!this.modifyDisabledCriteria" style="margin-top: -20px;" small color="success">Save</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="!initialLoader">
        <v-col class="col-md-3">
          <span class="d-flex flex-column" v-if="!this.modifyDisabledCriteria">
            <span class="d-flex flex-row">
              <h2>Add Item</h2>
              <v-icon class="ml-4" :color="scanStatusColor">mdi-barcode-scan</v-icon>
              <v-icon class="ml-4" @click="openQuickListDialog" color="warning">mdi-lightning-bolt</v-icon>
            </span>  
            <v-card outlined>
              <v-card-text>
                <span class="d-flex flex-row align-center">
                  <!-- <h2>Add Product</h2> -->
                  <!-- <v-btn class="ml-2" :loading="listeningForBarcodeScan" @click="listenForBarcodes()" fab small color="error" ><v-icon>mdi-barcode-scan</v-icon></v-btn> -->
                </span>
                <span class="d-flex flex-row align-center justify-center">
                  <v-text-field @focus="productSearchInputFocused = true" @blur="productSearchInputFocused = false" ref="searchProductInput" style="width: 100%;" :disabled="this.modifyDisabledCriteria" v-model="productSearchTerm" @change="searchProduct()" :loading="loadingSearchProduct" label="Search Product" dense outlined></v-text-field>
                </span>
                <!-- <span class="d-flex flex-column">
                  <span>Prepend the following for a refined search</span>
                  <span>id:[ID Number]</span>
                  <span>b:[Brand Name]</span>
                  <span>s:[Supplier Name]</span>
                </span> -->
                <p v-if="noSearchResults">No search results were found.</p>
                <span v-if="productSearchResults&&productSearchResults.length>0">
                  <span class="d-flex flex-row justify-center">
                    <b>Search Results</b>
                    <v-btn x-small color="info" style="margin-left: 10px;" @click="clearProductSearchResults">Clear</v-btn>
                  </span>
                  <div v-for="item in productSearchResults" :key="item.id">
                    <div class="d-flex flex-column justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                      <span v-if="item.focus==true">Selected</span>
                      <span class="d-flex flex-row justify-space-between">
                        <b style="text-align: left;">{{item.Brand?`${item.Brand.name} `:''}} {{item.name}}</b>
                        <span>
                          <v-btn class="mr-2" :disabled="!canProductSell(item)" :loading="item.isLoading" x-small fab color="success" @click="addProductToInvoice(item)" @keypress.enter="addProductToInvoice(item)"><v-icon>mdi-plus</v-icon></v-btn>
                          <v-btn v-if="getGlobalValue('QUICKLIST_ENABLED')=='true'" :disabled="!canProductSell(item) && addProductToQuicklistDialog.isLoading" :loading="item.isLoading" x-small fab color="warning" @click="attemptAddProductToQuicklist(item)"><v-icon>mdi-lightning-bolt</v-icon></v-btn>
                        </span>
                      </span>
                      <span class="d-flex flex-column align-start">
                        <span><b>SP: ${{item.regularPrice}}</b>, <span v-if="isAllowed('product', 'viewCostPrice')">PP: ${{item.salePrice}}</span></span>
                        <span v-if="getGlobalValue('VEC_INCLUDES_BLUETAGPRICE')==='true' && item.metadata?.blueTagPrice"><b>BLUE TAG: ${{item.metadata.blueTagPrice}}</b></span>
                        <span><b>{{item.ProductLocationJoins.find(x => x.locationId===getBranch).manageStock?'🟢':'🔴'}}QTY: {{item.ProductLocationJoins.find(x => x.locationId===getBranch)?item.ProductLocationJoins.find(x => x.locationId===getBranch).available:0}}</b><span v-if="getGlobalValue('showBranchQuantities')==='true'" class="ml-1"><span v-if="getGlobalValue('VEC_ALLOW_WAREHOUSE_INVENTORY_PULLING')==='true'">[{{item.ProductLocationJoins.filter(x => warehouses.find(y => y.id===x.locationId && y.id!==getBranch)).map(x => `${x.manageStock?'🟢':'🔴'}${lookupBranch(x.locationId)} (${x.available})`).join(", ")}}]</span><span v-else>[{{item.ProductLocationJoins.reduce((total, curr) => total+parseInt(curr.available), 0)}}]</span></span></span>
                        <span class="d-flex flex-row">
                          <router-link v-if="isAllowed('product', 'u')" :to="'/products/view/'+item.id">ID: {{item.id}}</router-link>
                          <span v-else>ID: {{item.id}}</span>
                          <span class="ml-2" v-if="item.sku">SKU:{{item.sku}}</span>
                        </span>
                      </span>
                    </div>
                  </div>
                </span>
              </v-card-text>
            </v-card>
          </span>
          <span v-else>
            <span class="d-flex flex-column">
              <span class="d-flex flex-row">
                <h2>Payments</h2>
                <v-btn :disabled="invoice.status<1 || this.orderBalance>=0" class="ml-3" x-small fab color="success" @click="openAddPayment" :loading="addPaymentLoading"><v-icon>mdi-plus</v-icon></v-btn>
                <!-- <v-btn :disabled="this.orderBalance!=0||paymentTable.items.length < 2" class="ml-3" x-small fab color="success" @click="printCombinedReceipt" :loading="combinedReceiptPrintLoading"><v-icon>mdi-printer</v-icon></v-btn> -->
              </span>
              <v-card class="mt-2" outlined>
                <v-card-text>
                  <h3>Balance: {{utils.formatCurrency(this.orderBalance)}}</h3>
                  <v-card outlined class="my-4" v-for="(payment, index) in paymentTable.items" :key="index">
                    <v-card-text class="d-flex flex-row justify-space-between ">
                      <div class="d-flex flex-column">
                        <span>{{utils.formatDate(payment.createdAt, 'withTime') }}</span>
                        <b>{{utils.formatCurrency(payment.amount) }}</b>
                      </div>
                      <div class="d-flex flex-row">
                        <dynamicButtonContainer :containerId="`${getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_ORDER_PAYMENTS')}`" :data="{status: invoice.status, id1: $route.params.id, id2: payment.id}"/>
                        <v-btn fab :disabled="invoice.status==-1" color="info" class="ml-1" @click="paymentTableRowClick(payment.id)" x-small><v-icon>mdi-chevron-right</v-icon></v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </span>  
          </span>
          <span v-if="getGlobalValue('VEC_INCLUDES_ORDER_SERIAL_NUMBERS_STORAGE_AND_QUERYING')==='true'">
            <div class="mt-2">
              <v-textarea
                auto-grow
                name="input-7-1"
                filled
                :label="`${serialNaming.singular} Numbers`"
                outlined
                v-model="invoice.metadata.serialNumbers"
                :disabled="this.modifyDisabledCriteria"
                @change="updateInvoice"
              ></v-textarea>
              <div style="margin-top: -20px;" class="d-flex flex-row align-start">
                <v-btn block v-if="!this.modifyDisabledCriteria" small color="success">Save {{serialNaming.plural}}</v-btn>
              </div>
            </div>
          </span>   
          <span v-if="getGlobalValue('VEC_INCLUDES_ORDER_COMMENTS')==='true'">
            <div class="mt-2">
              <v-textarea
                auto-grow
                name="input-7-1"
                filled
                label="Comments"
                outlined
                v-model="invoice.metadata.comments"
              ></v-textarea>
              <div style="margin-top: -20px;" class="d-flex flex-row align-start">
                <v-btn block small color="success" @click="updateComments" :loading="updateCommentsLoader">Save Comments</v-btn>
              </div>
            </div>
          </span>                  
        </v-col>       
        <v-col class="col-md-9">
          <v-row>
            <v-col>
              <span class="d-flex flex-row align-center">
                <h2>Order Items ({{(invoice.OrderLineItems?invoice.OrderLineItems.reduce((acc,x)=>acc+parseInt(x.quantity),0):0) || '-'}} units)</h2>
                <v-btn :disabled="modifyDisabledCriteria" v-if="getGlobalValue('VEC_INCLUDES_CREATE_ADHOC_PRODUCT_IN_ORDER')==='true'" color="info" class="ml-2" small @click="addAdhocLineItem"><v-icon left>mdi-plus</v-icon>Create Product</v-btn>
                <!-- <v-btn :disabled="modifyDisabledCriteria" v-if="getGlobalValue('VEC_INCLUDES_CREATE_OPEN_OLI')==='true'" color="info" class="ml-2" small @click="addOpenOLI">Service</v-btn> -->
              </span>
              <v-card class="mt-2" outlined>
                <v-container>
                  <v-row>
                    <v-col v-if="invoice">
                      <v-simple-table id="orderItemsTable">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>QTY</th>
                              <th>ID</th>
                              <th>Name</th>
                              <th v-if="getGlobalValue('VEC_INCLUDES_ORDER_LINE_ITEM_DESCRIPTION')==='true'">Description</th>
                              <th>SKU</th>
                              <th v-if="getGlobalValue('VEC_INCLUDES_ORDER_LINE_ITEM_DISCOUNT')==='true'">Discount</th>
                              <th>Unit Price</th>
                              <th>Total</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, i) in invoice.OrderLineItems" :key="i">
                              <td v-if="item.metadata?.quantities?.length <= 3">
                                <v-text-field v-for="(loc, index) in item.metadata.quantities" :key="index" :disabled="modifyDisabledCriteria" type="number" min="1" dense v-model="item.metadata.quantities[index].quantity" @change="updateLineItem(item)" :label="lookupBranch(loc.locationId)||'Deleted Location'" :max="item.max" style="min-height: 10px; font-weight: bold; font-size: 12px; padding: 0px; padding-top: 5px; width: 100px; margin-bottom: -27px;" outlined/>
                                <span>Total: {{item.quantity}}</span>
                              </td>
                              <td v-else-if="item.metadata?.quantities?.length > 3">
                                <span class="d-flex flex-row pb-2">
                                  <v-text-field v-for="(loc, index) in item.metadata.quantities" :key="index" :disabled="modifyDisabledCriteria" type="number" min="1" dense v-model="item.metadata.quantities[index].quantity" @change="updateLineItem(item)" :label="lookupBranch(loc.locationId)||'Deleted Location'" :max="item.max" style="min-height: 10px; font-weight: bold; font-size: 12px; padding: 0px; padding-top: 5px; width: 100px; margin-bottom: -27px;" outlined/>
                                </span>
                              </td>
                              <td v-else>
                                <v-text-field :disabled="modifyDisabledCriteria" type="number" min="1" dense v-model="item.quantity" @change="updateLineItem(item)" :max="item.max" style="width: 100px; margin-bottom: -27px;" outlined/>
                              </td>
                              <td>
                                <router-link v-if="isAllowed('product', 'u') && item.productId != 'adhoc'" :to="'/products/view/'+item.productId">{{item.productId}}</router-link>
                                <span v-else>{{item.productId}}</span>
                              </td>
                              <td style="text-align: left;">
                                <span v-if="!item.pending">{{item.productName}}</span>
                                <v-text-field style="width: 250px; margin-bottom: -26px;" outlined dense v-if="item.pending" v-model="item.productName"/>
                              </td>
                              <td v-if="getGlobalValue('VEC_INCLUDES_ORDER_LINE_ITEM_DESCRIPTION')==='true'">
                                <v-text-field :disabled="modifyDisabledCriteria" @change="updateOLIDescription(item.id)" style="width: 250px; margin-bottom: -26px;" outlined dense v-model="item.description"/>
                              </td>
                              <td style="">
                                <span v-if="!item.pending">{{item.sku}}</span>
                                <v-text-field style="width: 150px; margin-bottom: -26px;" outlined dense v-if="item.pending" v-model="item.sku" @change="trimBC(item)" />
                              </td>
                              <td v-if="getGlobalValue('VEC_INCLUDES_ORDER_LINE_ITEM_DISCOUNT')==='true'">
                                <span class="d-flex flex-row" v-if="!item.pending">
                                  <v-btn :disabled="modifyDisabledCriteria" @click="updateLineItemDiscountType(i)" text x-small style="font-size: 20px; border: 1px solid #ddd; height: 40px; border-top-right-radius: 0; border-bottom-right-radius: 0;" class="pa-0 ma-0" elevation="0">{{item.discountType===0?'%':'$'}}</v-btn>
                                  <v-text-field :disabled="modifyDisabledCriteria" min="0" type='number' style="border-bottom-left-radius: 0; border-top-left-radius: 0; width: 70px; margin-bottom: -26px;" dense @change="updateLineItem(item)" v-model="item.discountValue" outlined/>
                                  <v-btn :disabled="modifyDisabledCriteria" icon class="mt-3 ml-2" @click=openDiscountTargetDialog(i) x-small><v-icon>mdi-crosshairs-gps</v-icon></v-btn>
                                </span>
                              </td>
                              <!-- <td v-if="getGlobalValue('VEC_INCLUDES_ORDER_LINE_ITEM_COMMISSION')==='true'">
                                <span class="d-flex flex-row" v-if="!item.pending">
                                  <v-btn :disabled="modifyDisabledCriteria" @click="updateLineItemCommissionType(i)" text x-small style="font-size: 20px; border: 1px solid #ddd; height: 40px; border-top-right-radius: 0; border-bottom-right-radius: 0;" class="pa-0 ma-0" elevation="0">{{item.metadata.commission.type===0?'%':'$'}}</v-btn>
                                  <v-text-field :disabled="modifyDisabledCriteria" min="0" style="border-bottom-left-radius: 0; border-top-left-radius: 0; width: 70px; margin-bottom: -26px;" type="number" dense v-model="item.metadata.commission.value" outlined/>
                                </span>
                              </td> -->
                              <td>
                                <span v-if="!item.pending && invoice.metadata.paymentMethod!=2" class="d-flex flex-row align-center dense">
                                  <b style="font-size: 15px;">{{utils.formatCurrency(item.unitPrice)}}</b>
                                  <span v-if="item.metadata">
                                    <v-checkbox class="ml-2" :disabled="modifyDisabledCriteria" @change="updateLineItem(item)" v-model="item.metadata.unitPricePP" label="PP"></v-checkbox>
                                  </span>
                                </span>
                                <v-text-field v-else-if="item.pending" style="width: 100px; margin-bottom: -26px;" min="0" v-model="item.unitPrice" type="number" dense outlined></v-text-field>
                                <span v-else>
                                  <v-text-field :disabled="modifyDisabledCriteria" style="margin-bottom: -26px;" @change="updateLineItem(item)" min="0" v-model="item.unitPrice" type="number" dense outlined></v-text-field>
                                </span>
                              </td>
                              <td style="width: 15%;">
                                <!-- <span>{{utils.formatCurrency(item.unitPrice*item.quantity)}}</span> -->
                                <span style="font-size: 11px;" v-if="item.vatData.taxType == 2">{{utils.formatCurrency(item.metadata.lineTotal)}}</span>
                                <span class="d-flex flex-column" v-if="item.vatData.taxType == 2">
                                  <span style="font-size: 11px;">VAT {{ utils.formatCurrency(item.metadata.lineVatTotal) }}</span>
                                </span>
                                <span>{{utils.formatCurrency(item.metadata.lineTotal + (item.metadata.lineVatTotal || 0))}}</span>
                              </td>
                              <td>
                                <v-btn fab x-small class="mr-1" v-if="item.productId==='adhoc'" :disabled="!item.productName || item.unitPrice==0" :loading="item.loading" @click="completeAdhocItem(i)" color="success"><v-icon>mdi-check</v-icon></v-btn>
                                <v-btn :loading="productImageDialog.loading" class="mr-1" v-if="item.productId!=='adhoc' && getGlobalValue('VEC_INCLUDES_ORDER_LINE_PRODUCT_IMAGE_PREVIEW')==='true'" @click="showLineItemProductImage(item)" x-small fab color="info"><v-icon>mdi-image</v-icon></v-btn>
                                <v-btn class="mr-1" :disabled="modifyDisabledCriteria" @click="removeProduct(item)" x-small fab color="error"><v-icon>mdi-close</v-icon></v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <!-- <div v-for="item in invoice.orderItems" :key="item.id">
                        <div class="d-flex flex-row justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                          <span class="d-flex flex-column align-start">
                            <b style="text-align: left;">{{item.totalQuantity}} x {{item.name}}</b>
                            <b style="text-align: left;">${{item.regularPrice}}</b>
                          </span>
                        </div>
                      </div> -->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="5" class="d-flex">
                      <v-card v-if="invoice.OrderLineItems && invoice.OrderLineItems.length>0" outlined class="flex-grow-1">
                        <v-card-text>
                          <h3>Discounts</h3>
                          <span class="d-flex flex-column"> 
                            <span class="d-flex flex-column justify-start align-start">
                              <span class="d-flex flex-row">
                                <!-- <v-text-field dense :disabled="this.modifyDisabledCriteria" v-model="discountVal" type="number" @change="updateOrderDiscount" label="Discount Amount" outlined/> -->
                                <v-btn :disabled="modifyDisabledCriteria" @click='updateOrderDiscountType' text x-small style="font-size: 20px; border: 1px solid #ddd; height: 40px; border-top-right-radius: 0; border-bottom-right-radius: 0;" class="pa-0 ma-0" elevation="0">{{invoice?.metadata?.discountType==='percentage'?'%':'$'}}</v-btn>
                                <v-text-field :disabled="modifyDisabledCriteria" min="0" @change="updateInvoice" style="border-bottom-left-radius: 0; border-top-left-radius: 0; width: 110px; margin-bottom: -26px;" type="number" dense v-model="invoice.metadata.discountVal" outlined/>
                                <v-btn fab class="ml-2" v-if="!this.modifyDisabledCriteria" x-small color="success" ><v-icon>mdi-content-save</v-icon></v-btn>
                              </span>
                              <span class="d-flex flex-row">
                              </span>
                              <!-- <b class="mt-3">Applicable Promotions</b>
                              <div v-if="promotions && promotions.length > 0">
                                <div v-for="(promotion, index) in promotions" :key="index">
                                  <span>{{ promotion.name }} </span>
                                  <span> {{ promotionApplied(promotion) }} </span>
                                  <v-btn x-small color=info @click="updateOrderDiscount(index)">Apply</v-btn>
                                </div>
                              </div>
                              <div v-else>No promotions found</div> -->
                            </span>
                          </span>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col class="d-flex">
                      <v-card v-if="invoice.OrderLineItems && invoice.OrderLineItems.length>0" outlined class="flex-grow-2">
                        <v-container>
                          <v-row>
                            <v-col>
                              <h3>Other Charges</h3>
                              <span class="d-flex flex-column">
                                <v-checkbox :disabled="this.modifyDisabledCriteria" v-model="invoice.metadata.addCreditCardCharges" @change="updateOrderCreditCardCharges" label="Add Transaction Charges"></v-checkbox>
                                <span class="d-flex flex-row align-center" style="height: 100px;">
                                  <v-text-field dense :disabled="this.modifyDisabledCriteria || !invoice?.metadata?.addCreditCardCharges" v-model="invoice.metadata.creditCardChargesRate" type="number" min="0" max="100"  @change="updateOrderCreditCardCharges" label="Rate (%)" outlined/>
                                </span>
                                <v-btn v-if="!this.modifyDisabledCriteria" style="margin-top: -40px;" small color="success">Save</v-btn>
                                <v-checkbox v-if="getGlobalValue('VEC_ADMIN_DISPLAY_VE_ADD_VAT')=='true'" :disabled="this.modifyDisabledCriteria" v-model="invoice.metadata.addVat" @change="updateInvoice" label="VE Add VAT"></v-checkbox>
                              </span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                    <v-col class="d-flex">
                      <v-card v-if="invoice" outlined class="flex-grow-1">
                        <v-container>
                          <v-row>
                            <v-col>
                              <h2>Totals</h2>
                              <span class="d-flex flex-row justify-end">
                                <span class="d-flex flex-column text-right pr-2">
                                  <b>VAT Ex. Subtotal:</b>
                                  <b>VAT:</b>
                                  <b>Discounts:</b>
                                  <b v-if="this.invoice.deliveryInfo.deliveryType == 'delivery'">Delivery:</b>
                                  <b v-if="this.invoice.metadata.addCreditCardCharges">+TR. Charges ({{this.invoice.metadata.creditCardChargesRate}}%):</b>
                                  <h3>Grand Total:</h3>
                                </span>
                                <span class="d-flex flex-column text-right">
                                  <span>{{utils.formatCurrency(this.computedSubtotal|| 0)}}</span>
                                  <span>{{ utils.formatCurrency(this.computedVat)|| 0 }}</span>
                                  <span>{{utils.formatCurrency((discountApplied) ? invoice.discountAmount : 0)}}</span>
                                  <span v-if="this.invoice.deliveryInfo.deliveryType == 'delivery'">DL{{utils.formatCurrency(invoice.deliveryInfo.cost ? invoice.deliveryInfo.cost : 0)}}</span>
                                  <span v-if="this.invoice.metadata.addCreditCardCharges">CC{{utils.formatCurrency(this.computedCreditCardCharges)}}</span>
                                  <h3>{{utils.formatCurrency(this.computedGrandTotal)}}</h3>
                                </span>
                              </span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <!-- <v-btn outlined v-if="this.invoice.authorizerId"><v-icon color="success">mdi-check</v-icon> Sealed by {{this.lookupUsername(this.invoice.authorizerId)}}</v-btn> -->
              <!-- <v-btn style="margin-top: 10px;" :disabled="!invoiceOK" @click="signAndSave" :loading="saveInvoiceLoader" color='success'>Create Invoice</v-btn> -->
              <!-- <v-btn color="success" @click="openSIDAuthorizer({type: 'scanSign'})">Sign Thing</v-btn>
              <v-btn color="success" @click="openSIDAuthorizer({type: 'scanAuthorize', resource: 'order', requiredPerm: 'authorizeDiscounts'})">Authorize Thing</v-btn> -->
            </v-col>
          </v-row>
        </v-col>
        <!-- <v-col class="col-md-2">
          <v-row>
          </v-row>
          <v-row>
            <v-col v-if="invoice.customerId">
              <span>
                <h2>Delivery</h2>
              </span>
              <v-card outlined>
                <v-container>
                  <v-row>
                    <v-col class="d-flex flex-row">
                      <h3>Delivery Information</h3>
                      <v-btn class="ml-3" v-if="!this.showDeliveryInfo" @click="showDeliveryInfo=true" x-small fab color="success"><v-icon>mdi-chevron-down</v-icon></v-btn>
                      <v-btn class="ml-3" v-else x-small fab color="error" @click="showDeliveryInfo=false"><v-icon>mdi-chevron-up</v-icon></v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="this.showDeliveryInfo">
                    <v-col class="d-flex flex-column">
                      <span>
                        <v-radio-group mandatory :disabled="this.modifyDisabledCriteria" @change="this.updateInvoice" v-model="invoice.deliveryInfo.deliveryType" row>
                          <v-radio
                              label="Pick Up"
                              value="pickup"
                          ></v-radio>
                          <v-radio
                              label="Delivery"
                              value="delivery"
                          ></v-radio>
                        </v-radio-group>
                      </span>
                      <span v-if="this.invoice.deliveryInfo.deliveryType == 'delivery'">
                        <span class="d-flex flex-column">
                          <span class="d-flex flex-row justify-center mb-1">
                            <h3>Delivery Address</h3>
                            <v-btn :disabled="this.modifyDisabledCriteria" v-if="selectedCustomer" color="info" class="ml-2" x-small fab @click="addressDialog=true"><v-icon>mdi-chevron-right</v-icon></v-btn>
                          </span>
                          <span class="d-flex flex-column">
                            <v-text-field dense class="mx-1" :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.address.line1" @change="checkCustomAddress" label="Address Line 1" outlined></v-text-field>
                            <v-text-field dense class="mx-1" :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.address.line2" @change="checkCustomAddress" label="Address Line 2" outlined></v-text-field>
                            <v-text-field dense class="mx-1" :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.address.city" @change="checkCustomAddress" label="Town/City" outlined></v-text-field>
                            <v-text-field dense class="mx-1" :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.address.country" @change="checkCustomAddress" label="Country" outlined></v-text-field>
                          </span>
                          <v-btn style="margin-top: -20px;" v-if="invoice.deliveryInfo.customAddress" color="info" @click="saveCustomAddressDialog=true" small>Save New Address</v-btn>
                          <h3 class="mt-2">Delivery Charges</h3>
                          <v-text-field :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.cost" @change="this.updateInvoice" min="0" label="Delivery Cost" type="number" outlined></v-text-field>
                        </span>
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-card outlined v-if="invoice.status>=1 && invoice.deliveryInfo.deliveryType==='delivery'">
                <v-container>
                  <v-row>
                    <v-col class="d-flex flex-row">
                      <h3>Delivery Schedule</h3>
                      <v-btn :disabled="invoice.status < 1" x-small fab class="ml-3" color="info" @click="createDeliveryA()"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-data-table
                        v-if="!initialLoader"
                        :headers="deliverySchedule.tableHeaders"
                        :items="deliverySchedule.data|| []"
                        :items-per-page="-1"
                        @click:row="rowClick"
                      >
                        <template v-slot:item.dueDate="{ item }">
                          <span>{{utils.formatDate(item.dueDate) }}</span>
                        </template>
                        <template v-slot:item.status="{ item }">
                          <span>{{parseDeliveryStatus(item.status) }}</span>
                        </template>
                        <template v-slot:item.numUnits="{ item }">
                          <span>{{ item.deliveryItems?item.deliveryItems.reduce((acc,x)=>acc+x.deliveryQty,0):0 }}</span>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-col> -->
      </v-row>
    </v-container>
    <v-dialog
        v-model="discountTargetDialog.isOpen"
        width="600px"
        scrollable
      >
        <v-card>
          <v-card-title class="text-h5">Discount Target</v-card-title>
          <v-card-text>
            <v-text-field :disabled="modifyDisabledCriteria" min="0" type='number' class="mt-3" v-model="discountTargetDialog.data.value" label="Enter the discount target price" outlined/>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!discountTargetDialog.data.value" color="primary" small @click="calculateAndApplyDiscount">
              Calculate & Apply Discount
            </v-btn>
            <v-btn color="primary" small @click="closeDiscountTargetDialog">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="customerCommentHistoryDialog"
        width="600px"
        scrollable
      >
        <v-card v-if="selectedCustomer.metadata && selectedCustomer.metadata.comments">
          <v-card-title class="text-h5">Customer Comment History</v-card-title>
          <v-card-text>
            <div style="border-radius: 7px; border: 1px solid rgba(0,0,0,0.25);" class="pa-2 mb-3" v-for="(comment, index) in selectedCustomer.metadata.comments.toReversed()" :key="index">
              <div class="d-flex flex-column">
                <b>{{comment.value}}</b>
                <span>{{utils.formatDate(comment.createdAt, 'withTime')}} by {{ lookupUsername(comment.createdBy) }}</span>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" small @click="customerCommentHistoryDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    <v-dialog v-model="productImageDialog.isOpen" width="unset">
      <span class="d-flex flex-row align-center justify-center">
        <img :src="productImageDialog.source" />
      </span>
    </v-dialog>

    <v-dialog
      v-model="printPreviewDialog.isOpen"
      width="11in"
      scrollable
    >
      <v-card v-if="printPreviewDialog.data">
        <v-card-title class="text-h5">Preview</v-card-title>
        <v-card-text>
          <div id="orderPreview" v-html="printPreviewDialog.data.job.htmlToPrint"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePrintPreviewDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addProductToQuicklistDialog.isOpen"
      width="600"
      scrollable
    >
      <v-card v-if="addProductToQuicklistDialog.data">
        <v-card-title class="text-h5">Add {{ addProductToQuicklistDialog.data.name }} to Quick List</v-card-title>
        <v-card-text>
          <v-radio-group v-model="addProductToQuicklistDialog.listId">
            <v-radio label="Favourites" :value="getGlobalValue('QUICKLIST_ROOT_ID')"/>
            <v-radio v-for="(list,index) of quickListDialog.data.lists" :key="index" :label="list.name" :value="list.id"/>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary"  @click="closeAddProductToQuicklist">
            Close
          </v-btn>
          <v-btn color="success" :loading="addProductToQuicklistDialog.isLoading" @click="addProductToQuicklist(addProductToQuicklistDialog.data, addProductToQuicklistDialog.listId)">
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="createReturnDialog.isOpen" width="500">
      <v-card>
        <v-card-title class="text-h5">Confirm Create Return</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="createReturnDialog.isOpen = false">Close</v-btn>
          <v-btn color="success" text @click="createNewReturn()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="quickListDialog.isOpen" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card style="background-color: rgba(255,255,255,0.8);">
        <v-card-title class="d-flex flex-column">
          <span class="d-flex flex-row">
            <h1>Quick Lists</h1>
            <v-btn v-if="!quickListDialog.editMode" class="ml-2" @click="beginQuickListDialogEditMode" fab x-small color="info"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn v-else class="ml-2" @click="closeQuickListDialogEditMode" fab x-small color="error"><v-icon>mdi-close</v-icon></v-btn>
            <v-progress-circular
              indeterminate
              color="green"
              v-if="quickListDialog.isLoading"
              class="mr-3"
            ></v-progress-circular>
          </span>
          <div v-if="quickListDialog.newList.show" class="d-flex flex-row mt-5">
            <v-text-field v-model="quickListDialog.newList.name" label="New List Name" outlined dense></v-text-field>
            <v-btn class="ml-2" @click="createQuickList()" color="success">Create List</v-btn>
            <v-btn class="ml-2" @click="updateQuickList()" color="info">Update Lists</v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <span class="d-flex flex-row" style="width: 100%;">
            <span :style="{'width': 30+'%', 'height': '85vh', 'overflow-y': 'scroll'}">
              <h3>Favourites</h3>
              <span v-for="(fav,index) of quickListDialog.data.favourites" :key="index" class="d-flex flex-row">
                <v-btn icon x-small v-if="quickListDialog.editMode" @click="removeFromFavouriteList(index)"><v-icon>mdi-close</v-icon></v-btn>
                <v-btn icon x-small v-if="quickListDialog.editMode" @click="moveFavouriteListUp(index)"><v-icon>mdi-arrow-up</v-icon></v-btn>
                <v-btn icon x-small v-if="quickListDialog.editMode" @click="moveFavouriteListDown(index)"><v-icon>mdi-arrow-down</v-icon></v-btn>
                <v-chip class="mb-2" @click="addProductToInvoice(fav.Product)" :style="{'border': (quickListDialog.currentColumn==0)&&(index==quickListDialog.columns[quickListDialog.currentColumn])?'3px solid black!important':'none'}" color="success">
                  {{ fav.Product?fav.Product.name:'' }} - {{ fav.Product?utils.formatCurrency(fav.Product.regularPrice):"" }}
                </v-chip>
              </span>
            </span>
            <span :style="{'width': 20+'%', 'height': '85vh', 'overflow-y': 'scroll'}">
              <h3>Lists</h3>
              <span>
                <span v-for="(list,index) of quickListDialog.data.lists" :key="index" class="d-flex flex-row flex-wrap mr-2 mb-2">
                  <v-btn icon x-small v-if="quickListDialog.editMode" @click="removeList(index)"><v-icon>mdi-close</v-icon></v-btn>
                  <v-btn icon x-small v-if="quickListDialog.editMode" @click="moveListUp(index)"><v-icon>mdi-arrow-up</v-icon></v-btn>
                  <v-btn icon x-small v-if="quickListDialog.editMode" @click="moveListDown(index)"><v-icon>mdi-arrow-down</v-icon></v-btn>
                  <v-menu
                    v-model="list.showConfirmDeleteCard"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon x-small v-bind="attrs" v-on="on" v-if="quickListDialog.editMode" ><v-icon>mdi-close</v-icon></v-btn>
                    </template>
                    <v-card>
                      <v-card-actions>
                        <v-btn @click="menu = false">Cancel</v-btn>
                        <v-btn color="primary" @click="removeList(index)">Remove</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                  <v-chip @click="navigateIntoList(index)" :style="{'border': (quickListDialog.currentColumn==1)&&(index==quickListDialog.columns[quickListDialog.currentColumn])?'3px solid black!important':'none'}" color="info">
                    {{ list?list.name:'' }}
                  </v-chip>
                </span>
              </span>
            </span>
            <span :style="{'width': 50+'%', 'height': '85vh', 'overflow-y': 'scroll'}">
              <h3>{{ quickListDialog.currentListName }} List</h3>
              <span v-if="quickListDialog.data.lists">
                <span v-for="(item,index) of (quickListDialog.data.lists[quickListDialog.listItemOpen])?.ProductCategoriesJoins" :key="index" @click="addProductToInvoice(item.Product)" class="d-flex flex-row flex-wrap mr-2 mb-2">
                  <v-btn icon x-small v-if="quickListDialog.editMode" @click="removeProductFromList(index)"><v-icon>mdi-close</v-icon></v-btn>
                  <v-btn icon x-small v-if="quickListDialog.editMode" @click="moveProductInListUp(index)"><v-icon>mdi-arrow-up</v-icon></v-btn>
                  <v-btn icon x-small v-if="quickListDialog.editMode" @click="moveProductInListDown(index)"><v-icon>mdi-arrow-down</v-icon></v-btn>
                  <v-chip :style="{'border': (quickListDialog.currentColumn==2)&&(index==quickListDialog.columns[quickListDialog.currentColumn])?'3px solid black!important':'none'}" color="success">
                    {{ item.Product?item.Product.name:'' }} - {{ item.Product?utils.formatCurrency(item.Product.regularPrice):"" }}
                  </v-chip>
                </span>
              </span>
            </span>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-chip-group>
            <v-chip large label color="error" @keypress.space="quickListDialog.isOpen = false" @keypress.enter="quickListDialog.isOpen = false" @click="quickListDialog.isOpen = false">Close</v-chip>
          </v-chip-group> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmSealDialog.isOpen" width="500">
      <v-card class="d-flex flex-column align-center justify-center">
        <v-card-title class="text-h5">Confirm Seal Order</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-chip-group>
            <v-chip large label color="success" @keypress.space="sealOrder()" @keypress.enter="sealOrder()" @click="sealOrder()" ref="sealOrderBtn"><v-progress-circular indeterminate v-if="confirmSealDialog.loading"/><span v-else>Confirm</span></v-chip>
            <v-chip large label color="error" @keypress.space="confirmSealDialog.isOpen = false" @keypress.enter="confirmSealDialog.isOpen = false" @click="confirmSealDialog.isOpen = false">Close</v-chip>
          </v-chip-group>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmCreateReturnDialog.isOpen" width="500">
      <v-card class="d-flex flex-column align-center justify-center">
        <v-card-title class="text-h5">Confirm Create Return</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-chip-group>
            <v-chip large label color="success" @keypress.space="createReturn()" @keypress.enter="createReturn()" @click="createReturn()" :loading="confirmSealDialog.loading" ref="createReturnBtn">Confirm</v-chip>
            <v-chip large label color="error" @keypress.space="closeAttemptCreateReturn()" @keypress.enter="closeAttemptCreateReturn()" @click="closeAttemptCreateReturn()">Close</v-chip>
          </v-chip-group>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="createCustomerDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Add or Create Customer
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <div v-if="newCustomer.suggestedMatches && newCustomer.suggestedMatches.length > 0">
            <h4>Suggested Matches</h4>
            <v-chip-group column selected-class="text-primary">
              <v-chip style="margin-bottom: 20px; margin-right: 5px;" v-for="suggestion in newCustomer.suggestedMatches" @keypress.enter="setCustomerAndClose(suggestion)" @keypress.space="setCustomerAndClose(suggestion)" @click="setCustomerAndClose(suggestion)" :key="suggestion.id" >
                <span>
                  <span>[{{suggestion.id}}] </span>
                  <b>{{suggestion.name}} </b>
                  <span><v-icon x-small>mdi-phone</v-icon>{{suggestion.phone}}</span>
                  <span v-if="suggestion.email"><v-icon>mdi-email</v-icon> {{suggestion.email}}</span>
                </span>
              </v-chip>
            </v-chip-group>
          </div>
          <v-text-field :loading="findCustomerByIdLoader" ref="customerDialogId" v-model="newCustomer.id" @keyup="findCustomerById" label="ID" outlined></v-text-field>
          <v-text-field :loading="findCustomerByPhoneLoader" ref="customerDialogPhone" v-model="newCustomer.phone" @keyup="findCustomerByPhone" label="Phone" outlined></v-text-field>
          <v-text-field :loading="findCustomerByNameLoader" ref="customerDialogName" v-model="newCustomer.name" @keyup="findCustomerByName" label="Name" outlined></v-text-field>
          <v-spacer/>
          <v-text-field :loading="findCustomerByEmailLoader" ref="customerDialogEmail" v-model="newCustomer.email" @keyup="findCustomerByEmail" label="Email" type="email" outlined></v-text-field>
          <span class="d-flex flex-row">
            <v-text-field label="Address Line 1" v-model="newCustomer.addresses[0].line1" outlined dense class="mr-2"/>
            <v-text-field label="Address Line 2" v-model="newCustomer.addresses[0].line2" outlined dense/>
          </span>
          <span class="d-flex flex-row">
            <v-text-field label="City" v-model="newCustomer.addresses[0].city" outlined dense class="mr-2"/>
            <v-text-field label="Country" v-model="newCustomer.addresses[0].country" outlined dense/>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-chip-group>
            <v-chip label large :disabled="orderCustomerCreateDisabledCriteria" class="primary" :loading="customerLoading" @keypress.enter="createCustomer()" @keyup.space="createCustomer()" @click="createCustomer">
              Create
            </v-chip>
            <v-chip label large class="error" @keyup.enter="closeCreateCustomer()" @keyup.space="closeCreateCustomer()" @click="closeCreateCustomer">
              Cancel
            </v-chip>
          </v-chip-group>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="customersDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Customers
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field v-model="customerSearchTerm" @change="searchCustomer" :loading="searchResultsLoader" label="Search Customers" outlined></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-card :key="i" v-for="(customer, i) in customerSearchResults" class="d-flex flex-row align-center">
            <span class="d-flex flex-column justify-start" style="text-align: left; padding: 20px;">
              <h3>{{customer.name}}</h3>
              <p v-if="customer.email">{{customer.email}}</p>
              <p v-if="customer.phone">{{customer.phone}}</p>
            </span>
            <v-btn @click="setCustomer(customer)" fab x-small color="success"><v-icon>mdi-plus</v-icon></v-btn>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCustomersDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="usersDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Users
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field v-model="userSearchTerm" @change="searchUser" :loading="searchResultsLoader" label="Search Users" outlined></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-card :key="i" v-for="(user, i) in userSearchResults" class="d-flex flex-row align-center">
            <span class="d-flex flex-column justify-start" style="text-align: left; padding: 20px;">
              <h3>{{user.firstName}} {{user.lastName}}</h3>
              <p v-if="user.email">{{user.email}}</p>
              <p v-if="user.phone">{{user.phone}}</p>
            </span>
            <v-btn @click="setUser(user)" fab x-small color="success"><v-icon>mdi-plus</v-icon></v-btn>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeUsersDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="addressDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Customer Addresses
        </v-card-title>
        <v-card-text v-if="selectedCustomer && selectedCustomer.addresses && selectedCustomer.addresses.length>0">
          <v-card :key="i" v-for="(addr, i) in selectedCustomer.addresses" class="d-flex flex-row pa-2 justify-space-between mb-2" outlined>
            <span class="d-flex flex-column text-left">
              <h3 v-if="addr.name">{{addr.name}}</h3>
              <span v-if="addr.line1"><b>Address: </b>{{addr.line1}}{{addr.line2?", "+addr.line2:""}}</span>
              <span v-if="addr.city"><b>Town/City: </b>{{addr.city}}</span>
              <span v-if="addr.country"><b>Country: </b>{{addr.country}}</span>
            </span>
            <v-btn @click="setAddress(addr)" fab x-small color="success"><v-icon>mdi-plus</v-icon></v-btn>
          </v-card>
        </v-card-text>
        <v-card-text v-else>
          <span>This customer has no addresses.</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeAddressDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="saveCustomAddressDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Save Custom Address
        </v-card-title>
        <v-card-text class="pt-2">
          <v-text-field type="text" dense outlined label="Name of Address" v-model="customAddressName"/>
          <div class="d-flex flex-column">
            <span v-if="invoice.deliveryInfo.address.line1">{{invoice.deliveryInfo.address.line1}}<span v-if="invoice.deliveryInfo.address.line2">, {{invoice.deliveryInfo.address.line2}}</span></span>
            <span v-if="invoice.deliveryInfo.address.city">{{invoice.deliveryInfo.address.city}}<span v-if="invoice.deliveryInfo.address.country">, {{invoice.deliveryInfo.address.country}}</span></span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCustomAddressDialog">
            Cancel
          </v-btn>
          <v-btn class="success" text @click="saveCustomAddress">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="createDeliveryDialog.isOpen" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Create New Delivery
        </v-card-title>
        <v-card-actions>
          <v-btn small text color="error" @click="cancelCreateDelivery()">
            Cancel
          </v-btn>
          <v-btn small color="success" @click="createDeliveryB">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addPaymentDialog.isOpen" scrollable max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Add Payment
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="addPaymentDialog.selectedType" row>
            <v-radio
              v-for="n in paymentTypes"
              :key="n.id"
              :label="n.name"
              :value="n.id"
            ></v-radio>
          </v-radio-group>
          <hr>
          <span class="mt-3 d-flex flex-row align-center">
            <h1>Total Due: {{utils.formatCurrency(Math.abs(this.orderBalance))}}</h1>
            <v-btn class="ml-3" v-if="this.orderBalance && this.lookupPaymentType(addPaymentDialog.selectedType)!='Credit Note'" :disabled="Math.abs(this.orderBalance)==addPaymentDialog.tenderedAmount||this.orderBalance>0" @click="()=>{addPaymentDialog.tenderedAmount = Math.abs(this.orderBalance).toFixed(2);}" color="info" >Tender Balance</v-btn>
          </span>
          <v-text-field style="margin-top: 10px;" v-model="addPaymentDialog.tenderedAmount" :disabled="this.lookupPaymentType(addPaymentDialog.selectedType)=='Credit Note'" type="number" label="Amount Tendered" outlined></v-text-field>
          <v-text-field v-if="this.lookupPaymentType(addPaymentDialog.selectedType)=='Cheque'" style="margin-top: 10px;" dense v-model="addPaymentDialog.chequeNumber" label="Cheque Number" outlined></v-text-field>
          <h1 v-if="this.getGlobalValue('VEC_ADMIN_ORDER_CHANGE_CALCULATION')=='true'">Change Due: 
            <span v-if="parseFloat(addPaymentDialog.tenderedAmount)+parseFloat(this.orderBalance)<0">{{utils.formatCurrency(0)}}</span>
            <span v-else>{{utils.formatCurrency((parseFloat(addPaymentDialog.tenderedAmount)+parseFloat(this.orderBalance))||0)}}</span>
          </h1>
          <span v-if="this.lookupPaymentType(addPaymentDialog.selectedType)=='Credit Note'">
            <v-radio-group v-model="addPaymentDialog.creditNote" @change="updateSelectedCreditNote">
              <v-radio
                v-for="(cn, index) in addPaymentDialog.creditNoteAvailable"
                :key="index"
                :label="cn.name"
                :value="cn.id"
              ></v-radio>
            </v-radio-group>
            <p v-if="addPaymentDialog.cnMessage">{{addPaymentDialog.cnMessage}}</p>
          </span>
          <v-text-field label="Notes" v-model="addPaymentDialog.notes"/>
        </v-card-text>
        <v-card-actions>
          <v-btn small text color="error" @click="closeAddPaymentDialog()">
            Cancel
          </v-btn>
          <v-btn :disabled="addPaymentDialogDisabledCriteria" :loading="addPaymentDialog.loading" small color="success" @click="addPayment">
            Confirm
          </v-btn>
          <v-btn :disabled="addPaymentDialogDisabledCriteria" :loading="addPaymentDialog.loading" small color="success" @click="addPaymentAndPrint">
            Confirm & Print Receipt
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewPaymentDialog.isOpen" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          View Payment
        </v-card-title>
        <v-card-text>
          <p>Date Received: {{utils.formatDate(viewPaymentDialog.paymentToDisplay.createdAt)}} {{utils.formatTime(viewPaymentDialog.paymentToDisplay.createdAt)}}</p>
          <p>Received By: {{lookupUsername(viewPaymentDialog.paymentToDisplay.receivedBy)}}</p>
          <p>Amount: {{utils.formatCurrency(viewPaymentDialog.paymentToDisplay.amount)}}</p>
          <p>Amount Tendered: {{utils.formatCurrency(viewPaymentDialog.paymentToDisplay.metadata?.tenderedAmount)}}</p>
          <p>Change Issued: {{utils.formatCurrency(parseFloat(viewPaymentDialog.paymentToDisplay.metadata?.tenderedAmount)-parseFloat(viewPaymentDialog.paymentToDisplay.amount))}}</p>
          <p>Payment Type: {{lookupPaymentType(viewPaymentDialog.paymentToDisplay.paymentType)}}</p>
          <p v-if="lookupPaymentType(viewPaymentDialog.paymentToDisplay.paymentType)=='Cheque'">Cheque Number: {{viewPaymentDialog.paymentToDisplay.metadata.chequeNumber}}</p>
          <span v-if="lookupPaymentType(viewPaymentDialog.paymentToDisplay.paymentType)=='Credit Note'">
            <span>Credit Notes Used: </span>
            <span v-if="Array.isArray(viewPaymentDialog.paymentToDisplay.metadata.creditNotes)">
              <span v-for="cn in viewPaymentDialog.paymentToDisplay.metadata.creditNotes" :key="cn.id">
                {{cn}}
              </span>
            </span>
            <span v-else>
              <span>{{viewPaymentDialog.paymentToDisplay.metadata.creditNotes}}</span>
            </span>

          </span>
          <p v-if="viewPaymentDialog.paymentToDisplay.cnMessage">{{viewPaymentDialog.paymentToDisplay.cnMessage}}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn small text color="error" @click="closeViewPaymentDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<!--    <v-dialog scrollable v-model="creditNotesDialog" width="500">-->
<!--      <v-card>-->
<!--        <v-card-title class="text-h5">-->
<!--          Credit Notes-->
<!--        </v-card-title>-->
<!--        <v-card-text>-->
<!--          <v-card :key="i" v-for="(note, i) in selectedCustomer.CreditNotes" class="d-flex flex-row align-center">-->
<!--            <span class="d-flex flex-column justify-start" style="text-align: left; padding: 20px;">-->
<!--              <h3>Credit Note #{{note.id}}</h3>-->
<!--              <p>Amount: {{utils.formatCurrency(note.amount)}}</p>-->
<!--            </span>-->
<!--            <v-btn @click="addCreditNote(note)" fab x-small color="success"><v-icon>mdi-plus</v-icon></v-btn>-->
<!--          </v-card>-->
<!--        </v-card-text>-->
<!--        <v-card-actions>-->
<!--          <v-spacer></v-spacer>-->
<!--          <v-btn class="error" text @click="closeCreditNotesDialog">-->
<!--            Cancel-->
<!--          </v-btn>-->
<!--        </v-card-actions>-->
<!--      </v-card>-->
<!--    </v-dialog>-->
    <!-- <canvas style="border: 1px solid black; background-color: white; cursor: crosshair;" @mousedown="mousedown" @mousemove="mousemove" @mouseup="sign = false" @mouseout="sign = false" /> -->
    <v-dialog
      v-model="printDialog.isOpen"
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Print
        </v-card-title>
        <v-card-text>
          <h3 style="margin: 0px;">Select item to print</h3>
          <v-radio-group v-model="printDialog.jobType" @change="filterPrinters" row mandatory>
            <v-radio
              label="Invoice Only"
              value="invoice"
            ></v-radio>
            <v-radio
              label="Delivery Note Only"
              value="deliveryNote"
            ></v-radio>
            <v-radio
              label="Job Ticket Only"
              value="jobTicket"
            ></v-radio>
            <v-radio
              label="Full Order Print"
              value="al"
              selected
            ></v-radio>
          </v-radio-group>
          <v-text-field v-if="printDialog.jobType=='deliveryNote'" style="margin-top: 10px;" dense v-model="printDialog.deliveryNote" type="number" label="Delivery Note To Print" outlined></v-text-field>
          <hr>
          <v-select
            class="mt-2"
            :items="filteredPrinters"
            item-text="name"
            item-value="id"
            label="Select a Printer"
            v-model="printDialog.printer"
            outlined
          ></v-select>
          <v-text-field style="margin-top: 10px;" dense v-model="printDialog.quantity" type="number" default="1" label="Quantity" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePrintDialog()">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="chordingDialog.isOpen" width="600">
        <v-card>
          <v-card-title class="d-flex flex-row justify-space-between">
            <h1>Let's go!</h1>
            <v-progress-circular
              indeterminate
              color="green"
              v-if="chordingDialog.isLoading"
            ></v-progress-circular>
            <span>
              <span v-for="(key,index) in chordingDialog.chord" :key="index">
                  <v-chip x-small dense>{{key.toUpperCase()}}</v-chip>
              </span>
            </span>
          </v-card-title>
          <v-card-text class="d-flex flex-row flex-wrap">
            <v-chip color="info" class="mb-1 mr-2"><v-avatar left class="info darken-4">0</v-avatar> Set Default Customer</v-chip>
            <v-chip color="info" class="mb-1 mr-2"><v-avatar left class="info darken-4">1</v-avatar> Product Search</v-chip>
            <v-chip color="info" class="mb-1 mr-2"><v-avatar left class="info darken-4">2</v-avatar> Find/Create Customer</v-chip>
            <v-chip color="info" class="mb-1 mr-2"><v-avatar left class="info darken-4">3</v-avatar> Attempt Seal Order</v-chip>
            <v-chip color="info" class="mb-1 mr-2"><v-avatar left class="info darken-4">4</v-avatar> Add Cash Payment</v-chip>
            <v-chip color="info" class="mb-1 mr-2"><v-avatar left class="info darken-4">5</v-avatar> Add Linx Payment</v-chip>
            <v-chip color="info" class="mb-1 mr-2"><v-avatar left class="info darken-4">6</v-avatar> SCTP</v-chip>
            <v-chip color="info" class="mb-1 mr-2"><v-avatar left class="info darken-4">7</v-avatar> SLTP</v-chip>
            <v-chip color="info" class="mb-1 mr-2"><v-avatar left class="info darken-4">N</v-avatar> Create New Order</v-chip>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <span>Press <v-chip small dense>X</v-chip> to clear, <v-chip small dense>Backspace</v-chip> to go back one, or <v-chip small dense>F2</v-chip> to cancel.</span>
          </v-card-actions>
        </v-card>
      </v-dialog>

    <v-dialog
      v-model="productScanDialog.isOpen"
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Product Scanned
        </v-card-title>
        <v-card-text>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="productScanDialog.loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
          <!-- {{this.productScanDialog.item}} -->
          <div v-if="this.productScanDialog.item" class="d-flex flex-row justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
            <span class="d-flex flex-column align-start">
              <b style="text-align: left;">{{this.productScanDialog.item.name}}</b>
              <span>SP: ${{this.productScanDialog.item.regularPrice}}</span>
              <span v-if="isAllowed('product', 'viewCostPrice')">PP: ${{this.productScanDialog.item.salePrice}}</span>
            </span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeProductScanDialog()">
            Close
          </v-btn>
          <v-btn :disabled="this.modifyDisabledCriteria" text color="success" @click="()=>{addProductToInvoice(productScanDialog.item); closeProductScanDialog()}">Add To Order</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="confirmAddPaymentDialog.isOpen"
        width="600"
    >
      <v-card>
        <v-card-title>
          Confirm Register Bypass
        </v-card-title>
        <v-card-text>
          <span>You currently don't have a register opened at the moment. Are you sure you want to continue with this payment before opening a register?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancelAddPaymentConfirm">Close</v-btn>
          <v-btn color="success" @click="confirmAddPayment">Make Payment Without a Register</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
#orderItemsTable th, #orderItemsTable td{
  text-align: center;
}
v-btn:focus {
  border-color:green!important;
}

v-chip:focus {
  border: 2px solid green!important;
}

</style>
<script>
  import axios from 'axios';
  // import moment from 'moment';
  import _ from 'lodash'
  import {mapGetters, mapMutations} from 'vuex'
  // import authorizer from '../../components/authorizer.vue'
  import scanSystem from "../../plugins/scanSystem"
  import utils  from "../../plugins/helpers"
  import dynamicButtonContainer from './../../components/dynamicButtonContainer.vue';
  import confirmedActionButton from './../../components/confirmedActionButton.vue';
  export default {
    components: {
      // authorizer,
      confirmedActionButton,
      dynamicButtonContainer
    },
    data () {
      return {
        utils: utils,
        testCurrentlyCreatingOrder: false,
        printHandlerLoading: false,
        quotationPrintLoading: false,
        updateCommentsLoader: false,
        ctx :  null,
        loader: false,
        voidLoader: false,
        initialLoader: false,
        tempRegisterValues: {
          featureEnabled: true,
          registerIsOpen: false,
          strictMode: false,

        },
        computedGrandTotalCounter: 0,
        customerCommentTextField: {
          isEditable: false,
          value: ''
        },
        discountTargetDialog: {
          isOpen: false,
          isLoading: false,
          data: {}
        },
        promotions: [],
        promotionReducer: 0,
        customerCommentHistoryDialog: false,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },
        combinedReceiptPrintLoading: false,
        productSearchInputFocused: false,
        discountTypes: [
          {name: "%", value: 0},
          {name: "$", value: 1}
        ],
        discountType: 'amount',
        discountVal: 0,
        invoice: {paymentType: 'full', deliveryInfo: {address: {line1: "", line2: "", city: "", country: ""}}, metadata: {grandTotal:0}, discount: 0},
        // customers: [],
        customerDisabledCriteria: false,
        productSearchResults: [],
        productSearchTerm: '',
        customerSearchTerm: '',
        userSearchTerm: '',
        noSearchResults: false,
        taxRate: 0,
        saveInvoiceLoader: false,

        addressDialog: false,
        saveCustomAddressDialog: false,
        customAddressName: "",

        customersDialog: false,
        customerValue: "",
        customerSearchResults: [],
        selectedCustomer: '',
        customerLoading: false,

        usersDialog: false,
        userSearchResults: [],
        selectedUser: '',

        createCustomerDialog: false,
        newCustomer: {addresses: [{}], suggestedMatches: [], suggestedMatchSelected: ''},
        nameRules: [
          v => !!v || 'Name is required'
        ],
        discountRules: [
          v => v<=( this.invoice.subtotal*(parseFloat(this.getGlobalValue('VEC_MAX_DISCOUNT_PERCENTAGE'))/100)) || `Cannot issue discount more than ${this.getGlobalValue('VEC_MAX_DISCOUNT_PERCENTAGE')}%.`,
        ],
        
        productScanDialog:{
          isOpen:false,
          loader: false,
          item: "",
        },
        existingData: [],
        listeningForBarcodeScan: false,
        productSearchBarcode: '',
        barcodeScanResult: {},
        discountAuth: '',
        ourAuthRequest: false,
        authorizerState: false,
        searchResultsLoader: false,
        loadingSearchProduct: false,
        numUpdates: 0,
        hidePaymentTypeFull: false,
        creditNotesDialog: false,
        printDialog: {
          isOpen: false,
          quantity: 1,
          jobType: '',
          deliveryNote: '',
          printer: '',
        },
        printPreviewDialog: {
          isOpen: false,
          data: '',
          loading: false,
        },
        singular: "Order",
        singularLower: "order",
        plural: "Orders",
        pluralLower: "orders",
        showDeliveryInfo: true,
        deliverySchedule: {
          tableHeaders:[
            {text: 'DN#', align: 'start', value: 'id'},
            { text: 'Delivery Date', value: 'dueDate' },
            { text: 'Units', value: 'numUnits' },
            { text: 'Status', value: 'status' },
          ],
          data: [],
        },
        paymentTable: {
          headers: [
            {text: 'Date', align: 'start', value: 'createdAt'},
            { text: 'Amount', value: 'amount' },
            { text: '', value: 'printReceipt' },
          ],
          items: [],
        },
        csr: null,
        syncStatus: 1, // 0 = updating; 1 = updateSuccess; 2 = updateFail
        printers: [],
        filteredPrinters: [],
        documentTypes: [],
        createDeliveryDialog: {
          isOpen: false
        },
        addPaymentDialog: {
          isOpen: false,
          selectedType: '',
          creditNotesAvailable: [],
          tenderedAmount: null,
          chequeNumber: null,
          creditNotes: [],
          cnMessage: null,
          notes: "",
          loading: false,
          bypass: false
        },
        viewPaymentDialog: {
          isOpen: false,
          paymentToDisplay: {},
        },
        createReturnDialog: {
          isOpen: false,
        },
        productImageDialog: {
          isOpen: false,
          source: '',
          loading: false,
          name:''
        },
        findCustomerByIdLoader: false,
        findCustomerByPhoneLoader: false,
        findCustomerByNameLoader: false,
        findCustomerByEmailLoader: false,
        confirmSealDialog: {
          isOpen: false,
          loading: false,
        },
        search: {
          value: "",
          type: "Products",
          types: ["Products", "Customers"],
          dialog: false,
          loading: false,
          results: [],
        },
        chordingDialog:{
          isOpen: false,
          chord: []
        },
        quickListDialog: {
          isOpen: false,
          isLoading: false,
          data: {
            favourites: [],
            lists: [],
          },
          newList: {show: false, name: ''},
          columns: [0,0,0], // 0 = favourites, 1 = lists, 2 = list items
          columnLengths: [0,0,0],
          currentColumn: 0,
          currentListName: '',
          listItemOpen: 0,
          editMode: false
        },
        addProductToQuicklistDialog: {
          isOpen: false,
          isLoading: false,
          data: {},
        },
        confirmCreateReturnDialog: {
          isOpen: false,
          isLoading: false,
          data: {},
        },
        scanStatusColor: '',
        directBigPrintLoading: false,
        directSmallPrintLoading: false,
        directReceiptPrintLoading: false,
        directStatementPrintLoading: false,

        addPaymentLoading: false,
        paymentToConfirmType: "",
        confirmAddPaymentDialog: {
          isOpen: false,
        },

        warehouses: []
      }
    },
    watch: {
      scanBus: {
        handler(){
          console.log("Order.vue: ScanBus Changed")
          if(!this.scanBus.data || this.scanBus.data == "" || this.scanBus.data == undefined){
            console.log("Watcher detected that scanbus was reset. Terminating handoff to reserving handler: orderHandler.")
            return
          }
          if(this.scanBus.handler == 'order'){
            console.log('Order Handler taking over.')
            this.orderHandler()
          }
        },
        deep: true
      },
    },
    created(){
      this.debouncedUpdateInvoice = _.debounce(this.updateInvoice, 500)
    },
    beforeDestroy(){
      this.deactivateOrderScanSense()
    },
    async mounted(){
      try{
        this.initialLoader = true;
        let res = await axios.get(`${this.getEndpoint}/api/orders/${this.$route.params.id}`)
        if(res.data.error) throw res.data.error
        if(res.data.data.Customer && res.data.data.Customer.CreditNotes){
          this.addPaymentDialog.creditNoteAvailable = res.data.data.Customer.CreditNotes
        //{id: 1, type: 0, status: 0, amount: 200, name: 'CN-1 | Normal | Valid | $200'},
          this.addPaymentDialog.creditNoteAvailable = this.addPaymentDialog.creditNoteAvailable.map(x=>{
            return {
              id: x.id,
              status: x.status,
              amount: x.amount,
              expiresAt: x.expiresAt,
              name: `CN-${x.id} -- Value: $${x.amount} -- Expires At: ${utils.formatDate(x.expiresAt)} `
            }
          })
        }
        let userPrinters = this.getUser.metadata.printers
        let printers = await axios.get(`${this.getEndpoint}/api/devices/`)
        if(!printers.data.error){
          this.documentTypes = printers.data.data.documentTypes
          let printerDeviceTypeId = (printers.data.data.deviceTypes.filter(x=>x.name.toLowerCase()==='printer')[0]).id //looks at the deviceType object from the response to determine the id of the printers device type
          this.printers = printers.data.data.devices.filter(x=>x.deviceType===printerDeviceTypeId)
          this.printers.forEach(printer=>{
            if(userPrinters && userPrinters.default && userPrinters.default.length>0){
              for(let y in userPrinters.default){
                if(printer.data.jobTypes.includes(y)){
                  if(printer.id==userPrinters.default[y]){
                    printer['isDefault'] = true
                    printer.name = printer.name + ` (Default ${y} printer)`
                  }else printer['isDefault'] = false
                }
              }
            }
          })
          this.filteredPrinters = this.printers
        }else{
          console.log("Error loading printers")
        }

        if(!res.data.data){
          this.snack("Invoice could not be found.")
          await this.$router.go(-1);
        }

        if(!res.data.data.discountAmount) res.data.data.discountAmount = 0
        else {
          if(res.data.data.status != 0 && !res.data.data.metadata?.discountVal){
            res.data.data.metadata.discountVal = this.utils.pff2(res.data.data.discountAmount)
            res.data.data.metadata.discountType = "amount"

          }
        }

        if(!res.data.data.OrderLineItems) res.data.data.OrderLineItems = []
        if(!res.data.data.metadata) res.data.data.metadata = {creditNotes:[], creditNotesTotal:0, grandTotal:0}
        if(!res.data.data.metadata.grandTotal) res.data.data.metadata.grandTotal = 0

        if(!res.data.data.deliveryInfo) res.data.data.deliveryInfo = {address: {line1: "", line2: "", city: "", country: ""}}

        this.invoice = res.data.data

        if(!this.invoice.metadata.paymentMethod) this.invoice.metadata.paymentMethod = 1

        if(!this.invoice.metadata)
          this.invoice.metadata = {grandTotal:0, csrPaidAt: null, commissionRate: 5, discountVal: 0}

        if(!this.invoice.metadata.discountVal) this.invoice.metadata.discountVal = 0
        if(!this.invoice.metadata.discountType) this.invoice.metadata.discountType = 'amount'

        if(!this.invoice.metadata.csrPaidAt) this.invoice.metadata.csrPaidAt = null
        if(!this.invoice.metadata.commissionRate) this.invoice.metadata.commissionRate = 5
        
        if(this.invoice.status!==0) this.showDeliveryInfo = false;

        let deliverySchedule = await axios.get(`${this.getEndpoint}/api/deliveries/byOrder/${this.$route.params.id}`)
        if(deliverySchedule.data.error) throw deliverySchedule.data.error

        this.deliverySchedule.data = deliverySchedule.data.data

        this.invoice.subtotal = 0
        this.updateTotals()

        if(this.invoice.customerId){
          let customer = await axios.get(`${this.getEndpoint}/api/customers/${this.invoice.customerId}`)
          if(customer.data.error) throw customer.data.error
          this.selectedCustomer = customer.data.data
          this.checkCustomAddress();
          // this.selectedCustomer.CustomerAdvanceAccounts = this.selectedCustomer.CustomerAdvanceAccounts.filter(x=>!x.closed)
        }
        if(this.invoice.Transactions){
          this.paymentTable.items = this.invoice.Transactions
        }

        if(this.invoice.OrderLineItems.length > 0){
          for(let i of this.invoice.OrderLineItems){
            if(i.metadata?.quantities?.length>0){
              i.currentApprovedWarehouseQty=[];
              for(let loc of i.metadata.quantities){
                i.currentApprovedWarehouseQty.push({locationId: loc.locationId, quantity: loc.quantity})
              }
              i.currentApprovedQty = i.metadata.quantities.reduce((a, c) => a+parseInt(c.quantity), 0);
            }
            else{
              i.currentApprovedQty = i.quantity
            }
          }
        }

        if(!this.invoice?.metadata?.addCreditCardCharges){
          this.invoice.metadata.addCreditCardCharges = false
          this.invoice.metadata.creditCardChargesRate = 0
          await this.updateInvoice()
        }

        if(!this.invoice?.metadata?.paperRefId){
          this.invoice.metadata.paperRefId = ''
        }

        if(this.getGlobalValue("MOUNT_ORDER_WITH_DEFAULT_CUSTOMER")=='true' && !this.invoice.customerId){
          await this.setCustomer({id: parseInt(this.getGlobalValue("DEFAULT_CUSTOMER"))})
        }

        this.quickListDialog.data = this.getQuickList

        if(this.quickListDialog?.data?.favourites.length > 0 && this.quickListDialog?.data?.lists.length > 0){
          this.quickListDialog.columnLengths = [
            this.quickListDialog.data.favourites.length-1,
            this.quickListDialog.data.lists.length-1,
            this.quickListDialog.data.lists.map(x=>x?.ProductCategoriesJoins.length)
          ]
  
          this.navigateIntoList(0)

        }
        
        this.activateOrderScanSense()

        for(let list of this.quickListDialog.data.lists){
          list.ProductCategoriesJoins.sort((a,b)=>a.order-b.order)
        }
        this.loadSelectedCustomerComments()

        let promotions = await axios.get(`${this.getEndpoint}/api/promotions/`)
        if(promotions.data.error) throw promotions.data.error

        this.promotions = promotions.data.data

        if(this.getGlobalValue("VEC_ALLOW_WAREHOUSE_INVENTORY_PULLING")==='true'){
          let warehouses = await axios.get(`${this.getEndpoint}/api/locations/warehouses`);
          if(warehouses.data.error) throw warehouses.data.error

          this.warehouses = warehouses.data.data;
          this.warehouses = this.warehouses.filter(x => x.id!==this.getBranch)
          this.warehouses = [{id: this.getBranch}].concat(this.warehouses);
        }
        console.log(this.invoice)

      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      }
      finally {
        this.initialLoader = false;
      }
    },
    // async created(){
    //   try {

    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    computed: {
      ...mapGetters([
        'getId',
        'getEndpoint',
        'lookupUsername',
        'isAllowed',
        'getUser',
        'paymentMethods',
        'paymentTypes',
        'lookupPaymentMethods',
        'lookupPaymentType',
        'getGlobalValue',
        'scanBus',
        'lookupPaymentTypeId',
        'getQuickList',
        'lookupTaxType',
        'getBranch',
        'lookupBranch',
        'serialNaming'
      ]),
      orderCustomerCreateDisabledCriteria(){
        console.log(this.newCustomer, this.getGlobalValue('VEC_CUSTOMER_CREATE_REQUIRES_PHONE'), this.getGlobalValue('VEC_CUSTOMER_CREATE_REQUIRES_EMAIL'))
        if(!this.newCustomer.name) return true
        if(!this.newCustomer.phone && this.getGlobalValue('VEC_CUSTOMER_CREATE_REQUIRES_PHONE')==='true') return true
        if(!this.newCustomer.email && this.getGlobalValue('VEC_CUSTOMER_CREATE_REQUIRES_EMAIL')==='true') return true
        if(this.getGlobalValue('VEC_CUSTOMER_CREATE_REQUIRES_ADDRESS')==='true'){
          if(!this.newCustomer.addresses[0].line1) return true
          if(!this.newCustomer.addresses[0].city) return true
          if(!this.newCustomer.addresses[0].country) return true
        }
        return false // create button enabled when false is returned
      },
      addPaymentDialogDisabledCriteria(){
        //returning true disables the button
        if(!this.addPaymentDialog.selectedType) return true
        if(!this.addPaymentDialog.tenderedAmount) return true
        if(parseFloat(this.addPaymentDialog.tenderedAmount)<0.01) return true
        // if(this.addPaymentDialog.tenderedAmount>Math.abs(this.orderBalance)) return true
        if(this.lookupPaymentType(this.addPaymentDialog.selectedType)=="Cheque" && !this.addPaymentDialog.chequeNumber) return true
        if(this.lookupPaymentType(this.addPaymentDialog.selectedType)=="Credit Note" && !this.addPaymentDialog.creditNote) return true
        if(this.lookupPaymentType(this.addPaymentDialog.selectedType)=="Credit Note"){
          if(this.addPaymentDialog.tenderedAmount < 0) return true
        }
        return false
      },
      orderBalance(){
        return this.paymentTable.items&&this.paymentTable.items.length>0?parseFloat(this.paymentTable.items.reduce((acc,x)=>acc+parseFloat(x.amount),0)-parseFloat(this.computedGrandTotal)):(0-this.computedGrandTotal)
      },
      discountOk: function(){
        if(!this.discountApplied && this.invoice.discountAmount) return false
        return true
      },
      discountApplied(){
        if(this.invoice.discountAmount && this.invoice.discountAmount > 0)
          return true
        return false
      },
      modifyDisabledCriteria(){
        return parseInt(this.invoice.status) !== 0
      },
      computedSubtotal(){
        let sub = 0
        if(this.invoice?.OrderLineItems?.length>0){
          for(let line of this.invoice?.OrderLineItems){
            sub += this.utils.pff2(line.metadata.lineTotal)
            // if(line.vatData.taxType == 1){ // non vat
            //   sub += parseFloat(line.metadata.lineTotal)
            // }
            // if(line.vatData.taxType == 2){ // vat incl
            //   let div = 1+parseFloat(line.vatData.value);
            //   div = div/parseFloat(line.vatData.value);
            //   let vatAmount = parseFloat((parseFloat(line.metadata.lineTotal)/parseFloat(div)).toFixed(2));
            //   sub += parseFloat(line.metadata.lineTotal-vatAmount)
            // }
            // if(line.vatData.taxType == 3){ // vat excl
            //   sub += parseFloat(line.metadata.lineTotal)
            // }
          }  
        }
        return sub
      },
      computedVat(){
        let v = 0
        if(this.invoice?.OrderLineItems?.length>0 && this.getGlobalValue('VEC_VAT_RATE')){
          let vr = this.getGlobalValue('VEC_VAT_RATE')
          if(!vr) vr = 0
          for(let line of this.invoice?.OrderLineItems){
            console.log(line)
            if(line.vatData.taxType == 1){ // non vat
              v += 0
            }
            if(line.vatData.taxType == 2){ // vat incl
              // let div = 1+parseFloat(vr);
              // div = div/parseFloat(vr);
              // v += parseFloat((parseFloat(line.metadata.lineTotal)/parseFloat(div)).toFixed(2));
              v += this.utils.pff2(line.vatData.lineVatTotal)
            }
            if(line.vatData.taxType == 3 && this.invoice.metadata.addVat){ // vat excl
              v += this.utils.pff2(parseFloat(vr)*this.utils.pff2(line.metadata.lineTotal))
            }
          }    
        }
        console.log(v)        
        return v
      },
      computedCreditCardCharges(){
        if(this.invoice?.OrderLineItems?.length>0 && this.invoice?.metadata?.addCreditCardCharges && this.invoice?.metadata?.creditCardChargesRate>0)
          return (this.computedSubtotal * (this.invoice?.metadata?.creditCardChargesRate/100)) || 0
        return 0
      },
      computedGrandTotal(){
        // this.computedGrandTotalCounter++
        let deliveryCost = parseFloat(this.invoice?.deliveryInfo?.cost)
        if(!deliveryCost || deliveryCost < 0) deliveryCost = 0
        let vat = 0
        if(this.getGlobalValue('VEC_VAT_RATE')){
          vat = parseFloat(this.computedVat)
        }
        if(this.invoice?.OrderLineItems?.length>0){
          let z = this.computedSubtotal + vat - parseFloat(this.invoice?.discountAmount) + deliveryCost + this.computedCreditCardCharges
          return z
        }
        return 0
      },
    },
    methods: {
      ...mapMutations([
        'updateWorkingId',
        'deleteWorkingId',
        'lockGlobalQueryBc',
        'unlockGlobalQueryBc',
        'openSIDAuthorizer',
        'closeSIDAuthorizer',
        'clearNewInvoiceItem',
        'clearExistingInvoiceItem',
        'setScanBus',
        'resetScanBus',
        'updateCurrentRegister',
        'setQuickList'
      ]),
      async updateComments(){
        try {
          this.updateCommentsLoader = true
          if(!this.invoice.metadata.comments || this.invoice.metadata.comments=="" ) throw "No Comment."
          let x = await axios.put(`${this.getEndpoint}/api/orders/comments/${this.$route.params.id}`, {comments: this.invoice.metadata.comments})
          if(x.data.error) throw x.data.error
        } catch (error) {
          console.log(error)
          this.snack(error.msg``)
        } finally {
          this.updateCommentsLoader = false
        }
      },
      async voidOrder(val){
        try {
          this.voidLoader = true
          let x = await axios.post(`${this.getEndpoint}/api/orders/changeStatus/voided/${this.$route.params.id}`, val)
          if(x.data.error) throw x.data.error
          this.invoice.status = -1;
          this.invoice.voidedAt = x.data.data.voidedAt
          this.invoice.voidedBy = x.data.data.voidedBy
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.voidLoader = false
        }
      },
      openDiscountTargetDialog(oliIndex){
        this.discountTargetDialog.isOpen = true
        this.discountTargetDialog.data.index = oliIndex
      },
      closeDiscountTargetDialog(){
        this.discountTargetDialog = {
          isOpen: false,
          isLoading: false,
          data: {}
        }
      },
      calculateAndApplyDiscount(){
        let i = this.discountTargetDialog.data.index
        let item = [...this.invoice.OrderLineItems][i]
        if(!item) return
        
        let discountDollarValue = parseFloat(item.unitPrice)-parseFloat(this.discountTargetDialog.data.value)
        if(discountDollarValue < 0){
          this.snack("The discount cannot be greater than the cost of the item.")
          return
        }

        item.discountValue = discountDollarValue.toFixed(2)
        item.discountType = 1
        this.invoice.OrderLineItems.splice(i,1,item)
        this.$forceUpdate()
        this.closeDiscountTargetDialog()
        this.updateLineItem(this.invoice.OrderLineItems[i])
      },
      promotionApplied(promo){
        if(promo.metadata){
          let cond = true
          if(cond){ // promotion is between start and end date or is after start date and ongoing is true
            if(!promo.metadata.availableToType || promo.metadata.availableToType == 'All'){
              if(!promo.metadata.condition || promo.metadata.condition == 'None'){
                if(!promo.metadata.reward) return false
                if(promo.metadata.reward == 'Discount on Order'){
                  let val = 0
                  if(promo.metadata.discountType == 'Percentage'){
                    val = this.computedSubtotal*(parseFloat(promo.metadata.discountValue)/100)
                  }
                  else if(promo.metadata.discountType == 'Amount'){
                    val = parseFloat(promo.metadata.discountValue)
                  }
                  console.log(val)
                  // this.promotionReducer += val
                }
                else if(promo.metadata.reward == 'Discount on Products'){
                  return false
                }
                else if(promo.metadata.reward == 'Gift Card'){
                  return false
                }
                else if(promo.metadata.reward == 'Free Items'){
                  return false
                }
                console.log(this.promotionReducer)
                return true
              }else if(promo.metadata.condition == 'Minimum Order Amount'){
                return false
              }else if(promo.metadata.condition == 'Purchase Product Combination'){
                return false
              }
            }else if(promo.metadata.availableToType == 'New Customers'){
              return false
              // if(!this.selectedCustomer) return false
              // console.log(this.selectedCustomer)
              // if(this.selectedCustomer.Orders.length > 0) return false
            }else if(promo.metadata.availableToType == 'Existing Customers'){
              return false
              // if(!this.selectedCustomer) return false
              // if(this.selectedCustomer.Orders.length < 1) return false
            }
            return false
          }
        }
      },
      loadSelectedCustomerComments(){
        if(this.selectedCustomer && !this.selectedCustomer.metadata){
          this.selectedCustomer.metadata = {comments: []}
        }

        if(this.selectedCustomer && this.selectedCustomer.metadata && !this.selectedCustomer.metadata.comments){
          this.selectedCustomer.metadata.comments = []
        }

        if(this.selectedCustomer && this.selectedCustomer.metadata && this.selectedCustomer.metadata.comments && this.selectedCustomer.metadata.comments.length > 0){
          this.customerCommentTextField.value = this.selectedCustomer.metadata.comments[this.selectedCustomer.metadata.comments.length-1].value
          this.customerCommentTextField.createdAt = this.selectedCustomer.metadata.comments[this.selectedCustomer.metadata.comments.length-1].createdAt
          this.customerCommentTextField.createdBy = this.selectedCustomer.metadata.comments[this.selectedCustomer.metadata.comments.length-1].createdBy
          this.customerCommentTextField.isVisible = this.selectedCustomer.metadata.comments[this.selectedCustomer.metadata.comments.length-1].isVisible
        }else{
          this.customerCommentTextField.value = ''
          this.customerCommentTextField.createdAt = ''
          this.customerCommentTextField.createdBy = ''
          this.customerCommentTextField.isVisible = ''
        }
      },
      trimBC(prod){
        if(!prod.sku) return
        prod.sku = prod.sku.replace(/\\\/\/\\=/,'')
      },
      beginQuickListDialogEditMode(){
        this.quickListDialog.newList.show = true
        this.quickListDialog.editMode = true
      },
      closeQuickListDialogEditMode(){
        this.quickListDialog.newList.show = false
        this.quickListDialog.editMode = false
      },
      moveFavouriteListUp(currentIndex){
        let removed = this.quickListDialog.data.favourites.splice(currentIndex,1)
        this.$forceUpdate()
        this.quickListDialog.data.favourites.splice(currentIndex-1,0,removed[0])
        this.$forceUpdate()
        this.quickListDialog.data.favourites.forEach((x,index)=>{
          x.order = index
        })
      },
      moveFavouriteListDown(currentIndex){
        let removed = this.quickListDialog.data.favourites.splice(currentIndex,1)
        this.$forceUpdate()
        this.quickListDialog.data.favourites.splice(currentIndex+1,0,removed[0])
        this.$forceUpdate()
        this.quickListDialog.data.favourites.forEach((x,index)=>{
          x.order = index
        })
      },
      async removeFromFavouriteList(currentIndex){
        try {
          this.quickListDialog.isLoading = true
          let x = this.quickListDialog.data.favourites[currentIndex].productId

          let res = await axios.delete(`${this.getEndpoint}/api/quicklist/removeFromFavourites/${x}`)
          if(res.data.error) throw res.data.error

          this.quickListDialog.data.favourites.splice(currentIndex,1)
          this.$forceUpdate()
          this.snack("Item removed from Favourites Quick List.")

        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.quickListDialog.isLoading = false
        }
      },
      async removeList(currentIndex){
        try {
          this.quickListDialog.isLoading = true
          let x = this.quickListDialog.data.lists[currentIndex].id
          let res = await axios.delete(`${this.getEndpoint}/api/quicklist/removeList/${x}`)
          if(res.data.error) throw res.data.error
          this.quickListDialog.data.lists.splice(currentIndex,1)
          this.$forceUpdate()
          this.snack("List removed from Quick List.")
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.quickListDialog.isLoading = false
        }
      },
      async moveListUp(currentIndex){
        try {
          this.quickListDialog.isLoading = true
          let removed = this.quickListDialog.data.lists.splice(currentIndex,1)
          this.$forceUpdate()
          this.quickListDialog.data.lists.splice(currentIndex-1,0,removed[0])
          this.$forceUpdate()
          this.quickListDialog.data.lists.forEach((x,index)=>{
            x.order = index
        })
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.quickListDialog.isLoading = false
        }
      },
      async moveListDown(currentIndex){
        try {
          this.quickListDialog.isLoading = true
          let removed = this.quickListDialog.data.lists.splice(currentIndex,1)
          this.$forceUpdate()
          this.quickListDialog.data.lists.splice(currentIndex+1,0,removed[0])
          this.$forceUpdate()
          this.quickListDialog.data.lists.forEach((x,index)=>{
            x.order = index
          })
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.quickListDialog.isLoading = false
        }
      },
      async moveProductInListUp(currentIndex){
        try {          
          this.quickListDialog.isLoading = true
          let removed = this.quickListDialog.data.lists[this.quickListDialog.listItemOpen].ProductCategoriesJoins.splice(currentIndex,1)
          this.$forceUpdate()
          this.quickListDialog.data.lists[this.quickListDialog.listItemOpen].ProductCategoriesJoins.splice(currentIndex-1,0,removed[0])
          this.$forceUpdate()
          this.quickListDialog.data.lists[this.quickListDialog.listItemOpen].ProductCategoriesJoins.forEach((x,index)=>{
            x.order = index
          })
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.quickListDialog.isLoading = false
        }
      },
      async moveProductInListDown(currentIndex){
        try {
          this.quickListDialog.isLoading = true
          let removed = this.quickListDialog.data.lists[this.quickListDialog.listItemOpen].ProductCategoriesJoins.splice(currentIndex,1)
          this.$forceUpdate()
          this.quickListDialog.data.lists[this.quickListDialog.listItemOpen].ProductCategoriesJoins.splice(currentIndex+1,0,removed[0])
          this.$forceUpdate()
          this.quickListDialog.data.lists[this.quickListDialog.listItemOpen].ProductCategoriesJoins.forEach((x,index)=>{
            x.order = index
          })
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.quickListDialog.isLoading = false
        }
      },
      async removeProductFromList(index){
        try {
          this.quickListDialog.isLoading = true
          let productCategoryId = this.quickListDialog.data.lists[this.quickListDialog.listItemOpen].id
          let productId = this.quickListDialog.data.lists[this.quickListDialog.listItemOpen].ProductCategoriesJoins[index].productId

          let res = await axios.delete(`${this.getEndpoint}/api/quicklist/removeProduct/${productCategoryId}/${productId}`)
          if(res.data.error) throw res.data.error
          
          this.quickListDialog.data.lists[this.quickListDialog.listItemOpen].ProductCategoriesJoins.splice(index,1)
         
          this.$forceUpdate()
          this.snack("List removed from Quick List.")
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.quickListDialog.isLoading = false
        }
      },
      async updateQuickList(){
        try {
          this.quickListDialog.isLoading = true

          let obj = {
            order: {
              favourites: this.quickListDialog.data.favourites.map(x=>{ return {id: x.productId, order:x.order} }),
              lists: this.quickListDialog.data.lists.map(x=>{ return {id: x.id, order:x.order, name: x.name} }),
              listItems: this.quickListDialog.data.lists.map(x=>{ return {id: x.id, order: x.ProductCategoriesJoins.map(y=>{ return {id: y.productId, order: y.order}})}}),
            }
          }

          console.log(obj)

          let res = await axios.put(`${this.getEndpoint}/api/quicklist/updateLists`, obj)
          if(res.data.error) throw res.data.error

        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.quickListDialog.isLoading = false
        }
      },
      navigateOutOfList(){
        this.quickListDialog.listItemOpen = 0
        this.quickListDialog.currentListName = ""
      },
      navigateIntoList(index){
          this.quickListDialog.currentListName = this.quickListDialog.data.lists[index].name
          this.quickListDialog.listItemOpen = index
          this.quickListDialog.columns[2] = 0
      },      
      async createQuickList(){
        try {
          if(!this.quickListDialog.newList.name){
            this.snack("Name not provided.")
            return
          }

          let obj = {
            name: this.quickListDialog.newList.name,
            parent: parseInt(this.getGlobalValue("QUICKLIST_ROOT_ID")),
            order: this.quickListDialog.data.lists.length
          }
          
          let res = await axios.post(`${this.getEndpoint}/api/quicklist/`, obj)
          if(res.data.error) throw res.data.error
          
          this.snack("New Quick List Created!")
          this.quickListDialog.newList.show = false
          this.quickListDialog.newList.name = ""
        } catch (error) {
          console.log(error)
        }
      },
      attemptAddProductToQuicklist(prod){
        this.addProductToQuicklistDialog = {
          isOpen: true,
          isLoading: false,
          data: prod,
          listId: ''
        }
      },
      closeAddProductToQuicklist(){
        this.addProductToQuicklistDialog = {
          isOpen: false,
          isLoading: false,
          data: {},
          listId: ''
        }
      },
      async addProductToQuicklist(prod, listId){
        try {
          this.addProductToQuicklistDialog.isLoading = true

          let obj = {
            productId: prod.id,
            listId: listId
          }

          let res = await axios.post(`${this.getEndpoint}/api/quicklist/addProduct`, obj)
          if(res.data.error) throw res.data.error

          this.snack("Product Added to Quick List!")
          this.setQuickList(res.data.data)
          this.quickListDialog.data = this.getQuickList

          this.closeAddProductToQuicklist()

        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.addProductToQuicklistDialog.isLoading = false
        }
      },
      activateOrderScanSense(){
        console.log('ORDER.JS: ⭐✅ Order Scan Sense Active.')
        window.addEventListener('keydown', this.keydown)  
      },
      deactivateOrderScanSense(){
        console.log('ORDER.JS: ⭐❌ Order Scan Sense Inactive.')
        window.removeEventListener('keydown', this.keydown)  
      },
      keydown(e){
        
        //experiment
        // console.log('ORDER.JS:',e)
        if(e.key==='F1'||e.key==='F2'||e.key==='F4'){
          e.preventDefault()
          console.log(e.key, " was pressed.")
        }
        //end of experiment


        let elem = document.querySelector('button:focus');
        if(elem) {
          elem.blur();
        }
        const CHORDKEY = 'F2'
        const QUICKLISTKEY = 'F4'
        //formulates a scanstring and passes it to the scanbus
        if(this.getUser){ //only active when user is logged in

          //console.log('APP', e)
          this.cancelClearScanStringTimeout()
          this.clearScanStringTimeout()

          if(this.chordingDialog.isOpen){ // if we are in chording mode, suspend scanning operations until exited

            this.cancelClearScanStringTimeout()

            if(e.key===CHORDKEY){
              this.endShortcut()
            }else{
              this.handleChord(e)
            }
          }else if(this.quickListDialog.isOpen){ // if we are in quicklist mode, suspend scanning operations until exited

            this.cancelClearScanStringTimeout()

            if(e.key===QUICKLISTKEY){
              this.closeQuickList()
            }else{
              this.handleQuickListEvent(e)
            }

          }else{ //user is not in chording mode or QUICKLIST mode(could either be typing or scanning)
            
            if(e.key==="Enter"){ //if keypress is enter
              //determine if scanString is a barcode
              let isBarcode = false

              if(this.scanString?.length>5){  //a barcode will at least have a 5 char signature \//\= and at least 1 character. if this is not met, we can assume its not valid
                isBarcode = scanSystem.validateBarcode(this.scanString)
              }
              
              if(isBarcode){ // if it is a barcode 
                
                // push to scanbus 
                this.setScanBus({data: "x"})
                this.setScanBus({data: this.scanString})
              }

            }else{ // keypress is not enter
                
              if(e.key===CHORDKEY && (!this.scanString || this.scanString?.length == 0)){ // if  keyPress is CHORDKEY and the current scanString is empty ie a bc is not being streamed in
                
                //open chording Dialog
                this.cancelClearScanStringTimeout()
                this.chordingDialog.isOpen = true 
                //set scanbus for global chording
                this.setScanBus({handler:"globalChord", data: ""})
              } else if(e.key===QUICKLISTKEY && this.getGlobalValue("QUICKLIST_ENABLED")=='true' && (!this.scanString || this.scanString?.length == 0)){
                this.cancelClearScanStringTimeout()
                this.openQuickListDialog()
              }else if(e.key!='Shift' && e.key!='Alt' && e.key!='Control' && e.key!='Meta' && e.key!='Tab'){ //if keypress is not the CHORDKEY while scanstring is empty and is not SHIFT OR ALT

                // continue building scanstring
                if(!this.scanString || this.scanString === "" || this.scanString === undefined || this.scanString === null) // if this is excluded, the barcode will always have undefined as the first character
                  this.scanString = e.key
                else this.scanString += e.key

              } else if(e.key==='Tab'){
                console.log('Tab pressed')
                if(this.productSearchInputFocused && this.productSearchResults && this.productSearchResults.length > 0){
                  console.log("We must now try to focus on the first search result")
                  this.productSearchResults[2].focus = true
                  console.log(this.productSearchResults[2])
                }
                // if(this.createCustomerDialog){ // when tab is pressed in createCustomer dialog, we want to use tab to iterate over suggestions
                  // }
                // let x = this.$refs[this.focusMap[this.tabIndex]]
                // console.log(x.$el)
                // x.focus()
                // // console.log(this.$refs[x])
                // if(this.tabIndex >= this.focusMap.length-1)
                //   this.tabIndex = 0
                // else
                //   this.tabIndex++
              }
            }
          }
        }
      },
      checkEnterSpace(x){
        console.log(x)
      },
      openCreateCustomerDialog(){
        this.createCustomerDialog = true
        setTimeout(()=>{
          this.$refs.customerDialogPhone.focus()
        },100)
      },
      async orderHandler(){
        try {
          console.log("Order Handler Fired")
          this.setScanBus({data: ""})
          let type = scanSystem.validateType(this.scanBus.data)
          let s = this.scanBus.data.replace(/\\\/\/\\=/,'')
          let id = (s.replace(`${type}-`, ''));
          if(type==='INV'){
            this.scanStatusColor = 'green'
            console.log("Lets go to the scanned order: ", id)
            this.$router.push({path: `/orders/view/${id}`})
          }else{
            this.scanStatusColor = 'green'
            console.log("Lets add this product to the invoice: ", id, type)
            let prod = await this.lookupProduct(id, type)
            console.log(prod)
            if(!prod){
              this.scanStatusColor = ''
              console.log("Product could not be found at this time.")
              return
            }
            await this.addProductToInvoice(prod)
            this.scanStatusColor = ''
          }
        } catch (error) {
          this.scanStatusColor = ''
          console.log(error)
          this.snack(error)
        }
      },
      canProductSell(item){
        if(this.getGlobalValue("VEC_ALLOW_NEGATIVE_QUANTITIES")==="true"){
          return true
        }else if(this.getGlobalValue("VEC_ALLOW_WAREHOUSE_INVENTORY_PULLING")==="true"){
          let status = false;
          let filtered = item.ProductLocationJoins.filter(x => this.warehouses.find(y => y.id===x.locationId));

          for(let loc of filtered){
            if(item.id===1456) console.log(loc)
            if(!loc.manageStock){
              status = true;
              break;
            }
            if(loc.available>=1){
              status = true;
              break;
            }
          }

          return status
        }else{
          if(!item.ProductLocationJoins.find(x => x.locationId===this.getBranch)?.manageStock) return true;
          return !((item.ProductLocationJoins.find(x => x.locationId===this.getBranch)?item.ProductLocationJoins.find(x => x.locationId===this.getBranch).available:0)<1)
        }
      },
      async findCustomerById(){
        try {
          if(!this.newCustomer?.id){
            this.newCustomer.suggestedMatches = []
            return
          }

          this.findCustomerByIdLoader = true
          let res = await axios.get(`${this.getEndpoint}/api/customers/byId/${this.newCustomer.id}`)
          this.newCustomer.suggestedMatches = res.data.data
          console.log(this.newCustomer.suggestedMatches, res.data.data)
          this.findCustomerByIdLoader = false
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
          this.findCustomerByIdLoader = false
        }
      },
      async findCustomerByPhone(){
        try {
          if(!this.newCustomer?.phone){
            this.newCustomer.suggestedMatches = []
            return
          }
          if(this.newCustomer?.phone?.length < 3) return
          this.findCustomerByPhoneLoader = true
          let res = await axios.get(`${this.getEndpoint}/api/customers/byPhone/${this.newCustomer.phone}`)
          this.newCustomer.suggestedMatches = res.data.data
          this.findCustomerByPhoneLoader = false
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
          this.findCustomerByPhoneLoader = false
        }
      },
      async findCustomerByName(){
        try {
          if(!this.newCustomer?.name){
            this.newCustomer.suggestedMatches = []
            return
          }
          if(this.newCustomer?.name?.length < 3) return
          this.findCustomerByNameLoader = true
          let res = await axios.get(`${this.getEndpoint}/api/customers/byName/${this.newCustomer.name}`)
          this.newCustomer.suggestedMatches = res.data.data
          this.findCustomerByNameLoader = false
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
          this.findCustomerByNameLoader = false
        }
      },
      async findCustomerByEmail(){
        try {
          if(!this.newCustomer?.email){
            this.newCustomer.suggestedMatches = []
            return
          }
          if(this.newCustomer?.email?.length < 3) return
          this.findCustomerByEmailLoader = true
          let res = await axios.get(`${this.getEndpoint}/api/customers/byEmail/${this.newCustomer.email}`)
          this.newCustomer.suggestedMatches = res.data.data
          this.findCustomerByEmailLoader = false
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
          this.findCustomerByEmailLoader = false
        }
      },
      createDeliveryA(){
        this.createDeliveryDialog.isOpen = true
      },
      async createDeliveryB(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/deliveries`, {orderId: this.$route.params.id, createdBy: this.getId})
          if(res.data.error) throw res.data.error
          this.createDeliveryDialog.isOpen = false
          await this.$router.push({ path: `/deliveries/view/${res.data.data.id}`})
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      cancelCreateDelivery(){
        this.createDeliveryDialog.isOpen = false
      },
      filterPrinters(){
        this.filteredPrinters = this.printers.filter(x=>x.data.jobTypes.includes(this.cloudPrintDialog.jobType)).sort((a,b)=>b.isDefault-a.isDefault)
        this.cloudPrintDialog.printer = this.filteredPrinters.filter(x=>x.id==this.getUser.metadata.printers.default[this.cloudPrintDialog.jobType])[0]
      },
      removeCustomerFromInvoice(){
        if(this.invoice.Customer) delete this.invoice.Customer
        this.invoice.customerId = null
        this.selectedCustomer = {metadata:{comments:[]}};
        this.loadSelectedCustomerComments()
        this.customerSearchTerm = ''
        this.checkCustomAddress();
        this.invoice.deliveryInfo.address = {line1: "", line2: "", city: "", country: ""}
        this.updateInvoice()
      },
      customerSelected(){
        console.log(this.invoice)
      },
      async searchProduct(){
        try {
          if(this.productSearchTerm){
            this.loadingSearchProduct = true;
            let res = await axios.get(`${this.getEndpoint}/api/products/searchByAllVariationsNoLimit?val=${encodeURIComponent(this.productSearchTerm)}`);
            if(res.data.error) throw res.data.error
            this.productSearchResults = res.data.data

            if(this.productSearchResults.length == 0) this.noSearchResults = true
            else this.noSearchResults = false

            this.productSearchResults.forEach(x=>x.focus = false)
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }finally {
          this.loadingSearchProduct = false;
        }
      },
      clearProductSearchResults(){
        this.productSearchResults = []
        this.productSearchTerm = ""
      },
      watchInput(){
        if(this.productSearchTerm.length == 0) this.noSearchResults = false
      },
      async fetchCsr(){
        try {
          if(!this.invoice.authorizations.seal.authBy) throw "No CSR Associated with Order."
          let csr = await axios.get(`${this.getEndpoint}/api/users/forOrder/${this.invoice.authorizations.seal.authBy}`)
          if(csr.data.error) throw csr.data.error
          this.csrUser = csr.data.data
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      async removeProduct(e){
        try {
          this.loader = true
          this.syncStatus = 0
          if(!e.pending){
            let res = await axios.delete(`${this.getEndpoint}/api/orders/lineItem/${e.id}`)
            if(res.data.error) throw res.data.error
          }
          let i = (this.invoice.OrderLineItems.findIndex(x=>x.id===e.id))
          this.invoice.OrderLineItems.splice(i,1)
          await this.updateInvoice()
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.loader = false
          this.syncStatus = 1
        }
      },
      async updateOLIDescription(id){
        try {
          if(id == 'adhoc') return 
          this.loader = true
          this.syncStatus = 0
          let val = this.invoice.OrderLineItems.find(x=>x.id==id)
          if(!val || val == -1 || val.length == 0) throw "Description Error. Refresh this page."
          val = val.description
          let res = await axios.put(`${this.getEndpoint}/api/orders/lineItemDescription/${id}`, {description:val})
          if(res.data.error) throw res.data.error
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.loader = false
          this.syncStatus = 1
        }
      },
      addAdhocLineItem(){
        try{
          if(this.invoice.OrderLineItems.find(x => x.productId==='adhoc')) throw {msg: "Please save/remove pending line items before creating another."};
          let li = {
            id: 'adhoc',
            orderId: this.invoice.id,
            productId: 'adhoc',
            productName: '',
            sku: '',
            quantity: 1,
            unitPrice: 0,
            discountValue: 0,
            discountType: 0,
            vatData: {
              taxType: parseInt(this.getGlobalValue('VEC_VAT_DEFAULT_FOR_ADHOC_PRODUCT')),
              value: parseFloat(this.getGlobalValue('VEC_VAT_RATE')),
              lineVatTotal: 0
            },
            metadata: {
              serials: [],
              lineTotal: 0,
              commission: {type: 0, value: 0},
            },
            pending: true,
            loading: false
          }

          if(this.getGlobalValue('VEC_ALLOW_WAREHOUSE_INVENTORY_PULLING')==='true'){
            li.metadata.quantities = []
            for(let warehouse of this.warehouses){
              li.metadata.quantities.push({locationId: warehouse.id, quantity: (warehouse.id===this.getBranch)?1:0})
            }
            li.quantity = li.metadata.quantities.reduce((a, c) => a+=c.quantity, 0);
          }

          this.invoice.OrderLineItems.push(li);
        }
        catch (error) {
          console.log(error)
          this.snack(error.msg)
        }
      },
      addOpenOLI(){
        try{
          if(this.invoice.OrderLineItems.find(x => x.productId==='adhoc')) throw {msg: "Please save/remove pending line items before creating another."};
          let li = {
            id: 'adhoc',
            orderId: this.invoice.id,
            productId: 'adhoc',
            productName: '',
            sku: '',
            quantity: 1,
            unitPrice: 0,
            discountValue: 0,
            discountType: 0,
            metadata: {
              serials: [],
              lineTotal: 0,
              commission: {type: 0, value: 0},
            },
            pending: true,
            loading: false
          }

          if(this.getGlobalValue('VEC_ALLOW_WAREHOUSE_INVENTORY_PULLING')==='true'){
            li.metadata.quantities = []
            for(let warehouse of this.warehouses){
              li.metadata.quantities.push({locationId: warehouse.id, quantity: (warehouse.id===this.getBranch)?1:0})
            }
            li.quantity = li.metadata.quantities.reduce((a, c) => a+=c.quantity, 0);
          }

          this.invoice.OrderLineItems.push(li);
        }
        catch (error) {
          console.log(error)
          this.snack(error.msg)
        }
      },
      async completeAdhocItem(i){
        try{
          this.invoice.OrderLineItems[i].loading = true;
          this.invoice.OrderLineItems[i].pending = false;
          // this.invoice.OrderLineItems[i].metadata = {}
          await this.updateLineItem(this.invoice.OrderLineItems[i])
        }
        catch (error) {
          console.log(error)
          this.snack(error.msg)
        }
        finally {
          this.invoice.OrderLineItems[i].loading = false;
        }
      },
      async addProductToInvoice(p){
        try {
          p.isLoading = true
          let found = this.invoice.OrderLineItems.find(x=>x.productId==p.id);
           // format the product here
          if(!found){
            this.syncStatus = 0
            this.loader = true
            let rp = this.utils.pff2(p.regularPrice) || 0
            let pp = this.utils.pff2(p.salePrice) || 0
            let tempLineItem = {
              orderId: this.invoice.id,
              productId: p.id,
              productName: (p.Brand?p.Brand.name+' ':'')+p.name,
              sku: p.sku,
              quantity: 1,
              unitPrice: rp,
              promotionPrice: pp,
              discountValue: 0,
              discountType: 0,
              metadata: {
                serials: [],
                lineTotal: this.utils.pff2(1*(rp)),
                commission: {type: 0, value: 0},
              }
            }
            if(this.getGlobalValue('VEC_ALLOW_NEGATIVE_QUANTITIES')!=='true' && this.getGlobalValue('VEC_ALLOW_WAREHOUSE_INVENTORY_PULLING')==='true'){
              tempLineItem.metadata.quantities = []
              let initBranchForQty = null;
              let foundExisting = p.ProductLocationJoins.find(x => x.locationId===this.getBranch);
              //new
              // initBranchForQty = foundExisting.locationId;
              //end new
              //old
              if(foundExisting?.available>0){
                initBranchForQty = foundExisting.locationId;
              }
              else{
                let another = p.ProductLocationJoins.find(x => x.available>0);
                if(!foundExisting?.manageStock) another = foundExisting;
                if(!another) another = p.ProductLocationJoins.find(x => x.manageStock===false);
                if(!another) throw `The product has no stock to sell. Product ${p.id} cannot be added to the order.`
                initBranchForQty = another.locationId;
              }
              //end old

              for(let warehouse of this.warehouses){
                tempLineItem.metadata.quantities.push({locationId: warehouse.id, quantity: initBranchForQty===warehouse.id?1:0})
              }
              tempLineItem.quantity = tempLineItem.metadata.quantities.reduce((a, c) => a+c.quantity, 0);
            }
            else if(this.getGlobalValue('VEC_ALLOW_NEGATIVE_QUANTITIES')==='true' && this.getGlobalValue('VEC_ALLOW_WAREHOUSE_INVENTORY_PULLING')==='true'){
              tempLineItem.metadata.quantities = []
              let initBranchForQty = null;
              let foundExisting = p.ProductLocationJoins.find(x => x.locationId===this.getBranch);

              initBranchForQty = foundExisting.locationId;

              for(let warehouse of this.warehouses){
                tempLineItem.metadata.quantities.push({locationId: warehouse.id, quantity: initBranchForQty===warehouse.id?1:0})
              }
              tempLineItem.quantity = tempLineItem.metadata.quantities.reduce((a, c) => a+c.quantity, 0);
            }

            tempLineItem.metadata.lineTotal = this.utils.pff2(parseInt(tempLineItem.quantity)*(rp))

            let res = await axios.post(`${this.getEndpoint}/api/orders/lineItem/${this.$route.params.id}`, tempLineItem)
            if(res.data.error) throw res.data.error

            if(res.data.data.metadata?.quantities?.length>0){
              res.data.data.currentApprovedWarehouseQty=[];
              for(let loc of res.data.data.metadata.quantities){
                res.data.data.currentApprovedWarehouseQty.push({locationId: loc.locationId, quantity: loc.quantity})
              }
              res.data.data.currentApprovedQty = res.data.data.metadata.quantities.reduce((a, c) => a+parseInt(c.quantity), 0);
            }
            else{
              res.data.data.currentApprovedQty = res.data.data.quantity
            }

            this.invoice.OrderLineItems.push(res.data.data)
            this.updateTotals()
          }else{
            // if(!p.manageStock){
            if(found.metadata.quantities?.length>0){
              let fBranch = found.metadata.quantities.find(x => x.quantity>0);
              if(fBranch) fBranch.quantity++;
              found.quantity = found.metadata.quantities.reduce((a, c) => a+c.quantity, 0);
            }
            else{
              found.quantity++;
            }

            await this.updateLineItem(found)
            p.isLoading = false
            this.$forceUpdate()
            // return;
            // }
            // this.snack("Item already in invoice.")
          }
          p.isLoading = false
          // window.scroll({top: 0});
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.syncStatus = 1
          this.loader = false
          p.isLoading = false
        }
      },
      checkAndUpdateLines(){
        console.log("CHECK 1")
        let systemVatRate = parseFloat(this.getGlobalValue('VEC_VAT_RATE'))
        if(!systemVatRate) systemVatRate = 0;
        if(this.invoice.OrderLineItems.length < 1) this.resetDiscount()

        this.invoice.OrderLineItems.forEach(p=>{
          if(p.metadata.quantities?.length>0){
            let found = p.metadata.quantities.find(x => x.quantity>0);
            if(!found){
              let found2 = p.metadata.quantities.find(x => x.locationId===this.getBranch);
              if(found2) found2.quantity=1;
            }
            p.quantity = p.metadata.quantities.reduce((a, c) => a+c.quantity, 0);
          }
          else if(p.quantity < 1) p.quantity = 1
          if(p.discountValue < 0) p.discountValue = 0

          let discountVal = 0;

          if(p.discountType==0 && p.discountValue > 100){
            p.discountValue = 0
            this.snack("Discount Value Reset. It cannot exceed 100%.")
          }
          if(p.discountType==1 && parseFloat(p.discountValue) > parseFloat(p.unitPrice)){
            p.discountValue = 0
            this.snack("Discount Value Reset. It cannot exceed the unit price.")
          }

          if(p.discountType===1) discountVal = parseFloat(p.discountValue)
          else discountVal = (parseFloat(p.unitPrice)*(parseFloat(p.discountValue)/100))
          
          p.metadata.lineTotal = this.utils.pff2(parseInt(p.quantity)*(parseFloat(p.unitPrice)-discountVal))

          if(p.vatData.taxType == 2){
              let div = 1+parseFloat(systemVatRate);
              div = div/parseFloat(systemVatRate);
              p.metadata.lineTotalWithVat = p.metadata.lineTotal
              p.metadata.lineVatTotal = this.utils.pff2(parseFloat((parseFloat(p.metadata.lineTotal)/parseFloat(div))))
              p.metadata.lineTotal = this.utils.pff2(p.metadata.lineTotal - p.metadata.lineVatTotal)
          }

          p.metadata.vat = this.utils.pff2((p.metadata.lineTotal)*systemVatRate)

          if(p.metadata?.commission?.value < 0) p.metadata.commission.value = 0
          if(parseInt(p.metadata?.commission?.value) > 100) p.metadata.commission.value = 0
          
        })
      },
      async updateLineItem(item){
        try {
          if(item.pending) return;
          if(item.metadata.quantities?.length>0){
            for(let loc of item.metadata.quantities){
              if(!loc.quantity) loc.quantity = 0;
              else loc.quantity = parseInt(loc.quantity);
            }
            item.quantity = item.metadata.quantities.reduce((a, c) => a+parseInt(c.quantity), 0);
            console.log("item qty", item.quantity)
          }
          else if(!item.quantity) item.quantity = 1
          if(!item.discountValue) item.discountValue = 0
          this.syncStatus = 0
          this.loader = true

          if(item.productId !== 'adhoc'){
            if(item.metadata.unitPricePP){
              if(!item.Product.salePrice) item.Product.salePrice = 0
              item.unitPrice = item.Product.salePrice
            }else {
              if(!item.Product.regularPrice) item.Product.regularPrice = 0
              item.unitPrice = item.Product.regularPrice
            }
          }

          this.updateTotals()
          console.log("UPDATE LI 1")
          let res = await axios.put(`${this.getEndpoint}/api/orders/lineItem/${item.id}`, item)
          console.log("UPDATE LI 2")
          if(res.data.error){
            let ix = this.invoice.OrderLineItems.findIndex(x=>x.id==item.id)

            if(this.invoice.OrderLineItems[ix].metadata?.quantities?.length>0){
              for(let loc of this.invoice.OrderLineItems[ix].metadata.quantities){
                loc.quantity = this.invoice.OrderLineItems[ix].currentApprovedWarehouseQty.find(x => x.locationId===loc.locationId)?.quantity||0;
              }
              this.invoice.OrderLineItems[ix].quantity = this.invoice.OrderLineItems[ix].metadata.quantities.reduce((a, c) => a+parseInt(c.quantity), 0);
            }
            else{
              this.invoice.OrderLineItems[ix].quantity = this.invoice.OrderLineItems[ix].currentApprovedQty
            }

            this.updateTotals()
            this.$forceUpdate()
            throw res.data.error 
          }
          if(!res.data.success) this.snack("Frontend: Insufficient quantity in stock.")
          
          let rowId = item.id==='adhoc'?'adhoc':res.data.data.id;
          let ix = this.invoice.OrderLineItems.findIndex(x=>x.id==rowId)
          ix==-1?null:this.invoice.OrderLineItems.splice(ix, 1, res.data.data)

          if(this.invoice.OrderLineItems[ix].metadata?.quantities?.length>0){
            this.invoice.OrderLineItems[ix].currentApprovedWarehouseQty=[];
            for(let loc of this.invoice.OrderLineItems[ix].metadata.quantities){
              this.invoice.OrderLineItems[ix].currentApprovedWarehouseQty.push({locationId: loc.locationId, quantity: loc.quantity})
            }
            this.invoice.OrderLineItems[ix].currentApprovedQty = this.invoice.OrderLineItems[ix].metadata.quantities.reduce((a, c) => a+parseInt(c.quantity), 0);
          }
          else{
            this.invoice.OrderLineItems[ix].currentApprovedQty = this.invoice.OrderLineItems[ix].quantity
          }

          this.updateTotals()
          this.$forceUpdate()
        } catch (error) {
          if(item.id==='adhoc') item.pending=true;
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.syncStatus = 1
          this.loader = false
        }
      },
      updateLineDiscount(p){
        console.log("Update Line Discount")
        if(p.discountValue < 0) p.discountValue = 0
        if(p.discountType==0 && p.discountValue > 20) p.discountValue = 0
        this.updateLineTotal(p)
      },
      updateLineCommission(p){
        console.log("Update Line Commission")
        if(p.metadata.commission.value < 0) p.metadata.commission.value = 0
        if(parseInt(p.metadata.commission.value) > 100) p.metadata.commission.value = 0
        console.log("He")
        this.updateLineTotal(p)
      },
      updateTotals(){
        this.checkAndUpdateLines()
        this.updateOrderDiscount()
        let discountToApply = 0
        if(this.discountApplied)
          discountToApply = parseFloat(this.invoice.discountAmount)
        if(!this.invoice?.deliveryInfo?.cost){
          this.invoice.deliveryInfo.cost = 0
        }else if(parseFloat(this.invoice?.deliveryInfo?.cost) < 0){
          this.invoice.deliveryInfo.cost = 0
        }
        if(this.getGlobalValue('VEC_TOGGLE_VAT_FOR_VE') && this.invoice.metadata && this.invoice.metadata.addVat && this.invoice.metadata.addVat !== 0){
          console.log("INCLUDE VAT")
        }
        this.invoice.deliveryInfo.cost = parseFloat(this.invoice.deliveryInfo.cost)
        this.invoice.subtotal = this.invoice?.OrderLineItems.reduce((a,x)=>a+x.metadata?.lineTotal,0)
        let subSubTotal = this.invoice?.subtotal - discountToApply + this.invoice?.deliveryInfo?.cost
        this.invoice.metadata.grandTotal =  subSubTotal + (subSubTotal*(this.invoice.creditCardChargesRate/100))
        this.$forceUpdate()
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      resetExisting(){
        this.existingData = [];
      },
      async searchUser(){
        try {
          if(this.userSearchTerm){
            this.searchResultsLoader = true
            let res = await axios.get(`${this.getEndpoint}/api/users/search?val=${encodeURIComponent(this.userSearchTerm)}`);
            if(res.data.error) throw res.data.error
            this.userSearchResults = res.data.data
            if(this.userSearchResults && this.userSearchResults.length == 0) this.noSearchResults = true
            else this.noSearchResults = false
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }finally{
          this.searchResultsLoader = false
        }
      },
      async searchCustomer(){
        try {
          if(this.customerSearchTerm){
            this.searchResultsLoader = true
            let res = await axios.get(`${this.getEndpoint}/api/customers/search?val=${encodeURIComponent(this.customerSearchTerm)}`);
            if(res.data.error) throw res.data.error
            this.customerSearchResults = res.data.data
            if(this.customerSearchResults && this.customerSearchResults.length == 0) this.noSearchResults = true
            else this.noSearchResults = false
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }finally{
          this.searchResultsLoader = false
        }
      },
      async updateInvoice(){
        try {
          this.loader = true
          if(!this.invoice?.deliveryInfo?.cost) this.invoice.deliveryInfo.cost = 0
          this.syncStatus = 0
          this.updateTotals()

          if(!(this.invoice?.discountAmount <= this.invoice?.subtotal*(parseFloat(this.getGlobalValue('VEC_MAX_DISCOUNT_PERCENTAGE'))/100))){
            this.invoice.discountAmount = 0
            this.invoice.metadata.discountVal = 0
            this.snack(`The Discount was not applied. Discount value > ${this.getGlobalValue('VEC_MAX_DISCOUNT_PERCENTAGE')}%.`)
            return
          }

          if(this.modifyDisabledCriteria) return // very important it must be at the top
          if(this.initialLoader) return 
          if(this.invoice.status > 0){
            throw "❌ Order already Sealed."
          }
          let res = await axios.put(`${this.getEndpoint}/api/orders/${this.$route.params.id}`, this.invoice)
          if(res.data.error) throw res.data.error
          this.draftWasSaved = true
          this.updateTotals()
          this.syncStatus = 1
          console.log(this.invoice)
          console.log("END UPDATE INVOICE")
        } catch (error) {
          this.syncStatus = 2
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.loader = false
        }
      },
      resetDiscount(){
        this.invoice.discountAmount = 0
        this.invoice.metadata.discountVal = 0
        this.$forceUpdate()
      },
      updateOrderDiscountType(){
        if(this.invoice.metadata.discountType == 'percentage'){
          this.invoice.metadata.discountType = 'amount'
        }else {
          this.invoice.metadata.discountType = 'percentage'
        }
        this.updateInvoice()
        this.$forceUpdate()
      },
      updateOrderDiscount(){
        if(!this.invoice.discountAmount){
          this.invoice.discountAmount = 0
        }
        if(!this.invoice.metadata.discountVal){
          this.invoice.metadata.discountVal = 0
        }

        if(!this.invoice.metadata.discounts){
          this.invoice.metadata.discounts = []
        }
        // let d = this.promotions[index]
        // console.log(d)
        // if(d.metadata.reward == 'Discount on Order'){
        //   if(d.metadata.discountType == "Amount"){
        //     this.invoice.discountAmount = parseFloat(this.invoice.discountAmount) + parseFloat(d.metadata.discountValue)
        //   }else if(d.metadata.discountType == "Percentage"){
        //     this.invoice.discountAmount = parseFloat(this.invoice.discountAmount) + this.computedSubtotal*(parseFloat(d.metadata.discountValue)/100)
        //   }
        // }
        // this.updateInvoice()
        
        if(this.invoice?.metadata?.grandTotal<1 && this.invoice.metadata.discountVal > 0){
          this.invoice.discountAmount = 0
          this.invoice.metadata.discountVal = 0
          this.snack(`The Discount was not applied. Grand Total cannot be 0.`)
        }
        if(this.invoice.metadata.discountType == "amount"){
          this.invoice.discountAmount = this.invoice.metadata.discountVal
        }else if(this.invoice.metadata.discountType == "percentage"){
          this.invoice.discountAmount = this.utils.pff2(this.utils.pff2(this.computedSubtotal+this.computedVat)*(this.invoice.metadata.discountVal/100))
        }

        if(!this.invoice?.discountAmount) this.invoice.discountAmount = 0
        
      },
      closeUsersDialog(){
        this.usersDialog = false;
        this.userSearchTerm = ''
        this.userSearchResults = [];
        this.$forceUpdate()
      },
      async setUser(user){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/users/${user.id}`)
          if(res.data.error) throw res.data.error
          this.invoice.csrUser = res.data.data
          this.invoice.csrId = user.id
          await this.updateInvoice();
          this.closeUsersDialog();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      async removeCsrUser(){
        try {
          this.invoice.csrUser = null
          this.invoice.csrId = null
          await this.updateInvoice();
          this.closeUsersDialog();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      closeCustomersDialog(){
        this.customersDialog = false;
        this.customerSearchTerm = ''
        this.customerSearchResults = [];
        this.$forceUpdate()
      },
      async setCustomerAndClose(customer){
        await this.setCustomer(customer)
        this.createCustomerDialog = false
      },
      async setCustomer(customer){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/customers/withCreditNotes/${customer.id}`)
          if(res.data.error) throw res.data.error
          this.invoice.customerId = customer.id;
          this.selectedCustomer = res.data.data

          this.loadSelectedCustomerComments()
          
          if(res.data.data.CreditNotes){
            this.addPaymentDialog.creditNoteAvailable = res.data.data.CreditNotes
            this.addPaymentDialog.creditNoteAvailable = this.addPaymentDialog.creditNoteAvailable.map(x=>{
              return {
                id: x.id,
                status: x.status,
                amount: x.amount,
                expiresAt: x.expiresAt,
                name: `CN-${x.id} -- Value: $${x.amount} -- Expires At: ${utils.formatDate(x.expiresAt)} `
              }
            })
          }
          this.closeCustomersDialog();
          this.invoice.deliveryInfo.address = {line1: "", line2: "", city: "", country: ""}
          this.checkCustomAddress();
          await this.updateInvoice()
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      closeCreateCustomer(){
        this.createCustomerDialog = false;
        this.newCustomer = {addresses: [{}], suggestedMatches: [], suggestedMatchSelected: ''};
        this.customerLoading = false;
        this.existingData = [];
      },
      makeCustomerCommentEditable(){
        this.customerCommentTextField.isEditable = true
      },
      cancelCustomerCommentEditable(){
        this.customerCommentTextField.isEditable = false
        if(this.selectedCustomer.metadata.comments.length > 0){
          this.customerCommentTextField.value = this.selectedCustomer.metadata.comments[this.selectedCustomer.metadata.comments.length-1]
        }else {
          this.customerCommentTextField.value = ''
        }
      },
      async addCustomerComment(comment){
        try{
          this.customerLoading = true;
          let res = await axios.put(`${this.getEndpoint}/api/customers/addComment/${this.invoice.customerId}`, {comment: comment})
          if(res.data.error) throw res.data.error
          
          this.selectedCustomer.metadata.comments.push(res.data.data)
          this.customerCommentTextField.value = this.selectedCustomer.metadata.comments[this.selectedCustomer.metadata.comments.length-1].value
          this.customerCommentTextField.createdAt = this.selectedCustomer.metadata.comments[this.selectedCustomer.metadata.comments.length-1].createdAt
          this.customerCommentTextField.createdBy = this.selectedCustomer.metadata.comments[this.selectedCustomer.metadata.comments.length-1].createdBy
          this.customerCommentTextField.isVisible = this.selectedCustomer.metadata.comments[this.selectedCustomer.metadata.comments.length-1].isVisible
          this.customerCommentTextField.isEditable = false
          this.$forceUpdate()

          this.snack("Customer Comment Added.")
        }
        catch (error){
          console.error(error)
          this.snack(error.msg || error, "error")
        }
        finally {
          this.customerLoading = false;
        }
      },
      async updateCustomerComment(comment,index){
        try{
          this.customerLoading = true;
          let res = await axios.put(`${this.getEndpoint}/api/customers/updateComment/${this.invoice.customerId}/${index}`, comment)
          if(res.data.error) throw res.data.error
          
          this.snack("Customer Comment Updated.")
        }
        catch (error){
          console.error(error)
          this.snack(error.msg || error, "error")
        }
        finally {
          this.customerLoading = false;
        }
      },
      async deleteCustomerComment(index){
        try{
          this.customerLoading = true;
          let res = await axios.put(`${this.getEndpoint}/api/customers/deleteComment/${this.invoice.customerId}/${index}`)
          if(res.data.error) throw res.data.error
          
          this.snack("Customer Comment Deleted.")
        }
        catch (error){
          console.error(error)
          this.snack(error.msg || error, "error")
        }
        finally {
          this.customerLoading = false;
        }
      },

      async createCustomer(){
        try{
          this.customerLoading = true;
          let res = await axios.post(`${this.getEndpoint}/api/customers/createAdHoc`, this.newCustomer)
          if(res.data.error) throw res.data.error
          this.snack("Customer Created.")
          this.invoice.customerId = res.data.data.id
          this.selectedCustomer = res.data.data
          this.closeCreateCustomer();
          this.updateInvoice()
        }
        catch (error){
          console.error(error)
          this.snack(error.msg || error, "error")
        }
        finally {
          this.customerLoading = false;
        }
      },
      setAddress(addr){
        this.invoice.deliveryInfo.address = {line1: addr.line1||"", line2: addr.line2||"", city: addr.city||"", country: addr.country||"", name: addr.name};
        this.invoice.deliveryInfo.customAddress = false;
        this.updateInvoice()
        this.closeAddressDialog();
      },
      closeAddressDialog(){
        this.addressDialog = false;
      },
      async saveCustomAddress(){
        try{
          if(this.customAddressName.length===0) throw "A name is required."
          let addr = {...this.invoice.deliveryInfo.address};
          addr.name = this.customAddressName;
          let res = await axios.put(`${this.getEndpoint}/api/customers/addAddress/${this.selectedCustomer.id}`, addr)
          if(res.data.error) throw res.data.error
          this.snack("Address added successfully!");
          this.invoice.deliveryInfo.customAddress = false;
          let tempAddr = {line1: addr.line1, line2: addr.line2, city: addr.city, country: addr.country, name: addr.name}
          this.selectedCustomer.addresses.push(tempAddr);
          this.setAddress(tempAddr)
          this.closeCustomAddressDialog();
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      checkCustomAddress(){
        let val = false;

        if(this.invoice.deliveryInfo.address.line1==="" &&
            this.invoice.deliveryInfo.address.line2==="" &&
            this.invoice.deliveryInfo.address.city==="" &&
            this.invoice.deliveryInfo.address.country===""){
          this.invoice.deliveryInfo.customAddress = val;
          return;
        }

        val = true;

        if(this.selectedCustomer && this.selectedCustomer.addresses){
          for(let addr of this.selectedCustomer.addresses){
            if((this.invoice.deliveryInfo.address.line1?this.invoice.deliveryInfo.address.line1===addr.line1:true) &&
                (this.invoice.deliveryInfo.address.line2?this.invoice.deliveryInfo.address.line2===addr.line2:true) &&
                (this.invoice.deliveryInfo.address.city?this.invoice.deliveryInfo.address.city===addr.city:true) &&
                (this.invoice.deliveryInfo.address.country?this.invoice.deliveryInfo.address.country===addr.country:true)){
              val = false;
            }
          }
        }
        else{
          val = false;
        }

        this.invoice.deliveryInfo.customAddress = val;
        this.$forceUpdate();
      },
      closeCustomAddressDialog(){
        this.customAddressName = "";
        this.saveCustomAddressDialog = false;
      },
      listenForBarcodes(){
        this.lockGlobalQueryBc()
        console.log("Listening for Barcode Scan")
        this.listeningForBarcodeScan = true
        this.$refs.barcodeTerm.focus()
      },
      cancelListenForBarcodes(){
        this.unlockGlobalQueryBc()
        this.listeningForBarcodeScan = false
        this.$refs.barcodeTerm.blur()
        this.productSearchBarcode = ''
      },
      barcodeSearchProduct(){
        this.queryDialogSearch()
      },
      async queryDialogSearch(){
        try {
          let pId = (this.productSearchBarcode.slice(0,5)).replace(/^0+/, '');
          this.queryDialogText = `ID Scanned: ${this.fromBase62(pId)}`
          let res = await axios.get(`${this.getEndpoint}/api/products/searchByIdWithVariations/${this.fromBase62(pId)}`);
          if(res.data.error) throw res.data.error

          if(res.data.data===null){
            this.productSearchBarcode = ''
            throw "Invalid barcode value. Try again.";

          }else{
            this.barcodeScanResult = res.data.data
            this.cancelListenForBarcodes()

            this.addProduct(this.barcodeScanResult)
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      toBase62(n) {
        if (n === 0) {
          return '0';
        }
        var digits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        while (n > 0) {
          result = digits[n % digits.length] + result;
          n = parseInt(n / digits.length, 10);
        }

        return result;
      },
      fromBase62(s) {
        var digits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = 0;
        for (var i=0 ; i<s.length ; i++) {
          var p = digits.indexOf(s[i]);
          if (p < 0) {
            return NaN;
          }
          result += p * Math.pow(digits.length, s.length - i - 1);
        }
        return result;
      },
      attemptConfirmSeal(){
        this.confirmSealDialog.isOpen = true
        setTimeout(() => {
          this.$refs.sealOrderBtn.$el.focus()
        })
      },
      attemptCreateReturn(){
        this.confirmCreateReturnDialog.isOpen = true
        this.confirmCreateReturnDialog.isLoading = false
        setTimeout(() => {
          this.$refs.createReturnBtn.$el.focus()
        })
      },
      closeAttemptCreateReturn(){
        this.confirmCreateReturnDialog.isOpen = false
        this.confirmCreateReturnDialog.isLoading = false
      },
      async createReturn(){
        try {
            this.confirmCreateReturnDialog.isLoading = true
            let res = await axios.post(`${this.getEndpoint}/api/returns/fromOrder/${this.$route.params.id}`)
            if(res.data.error) throw res.data.error
            this.confirmCreateReturnDialog = {isOpen: false, isLoading: false}
            await this.$router.push({path: `/returns/view/${res.data.data.id}`})
        } catch (error) {
          console.log(error)
          this.snack(error)
        }
      },      
      async sealOrder(){
        try {
            this.confirmSealDialog.loading = true
            let res = await axios.post(`${this.getEndpoint}/api/orders/changeStatus/sealed/${this.$route.params.id}`)
            if(res.data.error) throw res.data.error
            console.log("Seal Data", res.data.data)
            this.invoice.status = res.data.data.status
            this.confirmSealDialog = {isOpen: false, loading: false}
            await this.openAddPayment()
        } catch (error) {
          console.log(error)
          this.snack(error)
        }
      },
      endShortcut(){
        this.clearScanString()
        this.chordingDialog.chord = []
        this.chordingDialog.isOpen = false
        this.chordingDialog.isLoading = false
      },
      async openQuickListDialog(){
        try {
          console.log("Launching quicklist")
          this.quickListDialog.isOpen = true
          this.quickListDialog.editMode = false
        } catch (error) {
          console.log(error)
        }
      },
      closeQuickList(){
        this.clearScanString()
        this.quickListDialog.isOpen = false
        this.quickListDialog.editMode = false
        this.quickListDialog.newList = {show: false, name: ''}
      },
      async handleChord(code){
        try {
          let key = code.key.toLowerCase()
          if(key !== "arrowdown" && key !== "arrowup"&&key !== "arrowright"&&key !== "arrowleft")
            this.chordingDialog.chord.push(key)
          if(this.chordingDialog.chord[this.chordingDialog.chord.length-1]==='x'){
            this.chordingDialog.chord = []
            this.search.results= []
          }
          if(this.chordingDialog.chord[this.chordingDialog.chord.length-1]==='backspace'){
            this.chordingDialog.chord.pop()
            this.chordingDialog.chord.pop()
            this.search.results = []
          }
          let str = this.chordingDialog.chord.join().replace(",","")
          if(str =='on' || str =='n' ){
            console.log("Create New Order")
            if(!this.testCurrentlyCreatingOrder) this.testCurrentlyCreatingOrder = true
            else {
              this.snack("Currently Creating an Order")
              return
            }
            this.attemptCreateInvoice()
            this.endShortcut()         
          }
          if(str ==='1'){
            if(this.invoice.status != 0){
              this.snack("Cannot perform action.")
              return
            }
            console.log("Focus on Search Product via F1")
            console.log(this.$refs)
            setTimeout(()=>{
              this.$refs.searchProductInput.focus()
              this.endShortcut()              
            },200)
          }
          if(str ==='2'){
            if(this.invoice.status != 0){
              this.snack("Cannot perform action.")
              return
            }
            console.log("Open Add Create Customer Dialog via F1")
            this.openCreateCustomerDialog()
            this.endShortcut()              
          }
          if(str ==='3'){
            if(!this.invoiceOK() || this.invoice.status !== 0){
              this.snack("Cannot seal.")
              return
            }
            console.log("Open Seal Dialog")
            this.attemptConfirmSeal()
            this.endShortcut()              
          }
          if(str ==='4'){
            if(this.invoice.status < 1){
              this.snack("Cannot add payment until order is sealed.")
              return
            }
            console.log("Open Payment Dialog")
            this.openAddPayment("Cash")
            this.endShortcut()              
          }
          if(str ==='5'){
            if(this.invoice.status < 1){
              this.snack("Cannot add payment until order is sealed.")
              return
            }
            console.log("Open Payment Dialog")
            this.openAddPayment("Linx")
            this.endShortcut()              
          }
          if(str ==='6'){
            if(!this.invoiceOK() || this.invoice.status !== 0){
              this.snack("Cannot perform action.")
              return
            }
            console.log("SCTPSeal Order, Add Tender balance, Add Cash Payment and Print Receipt")
            
            this.chordingDialog.isLoading = true
            
            await this.sealOrder()
            this.openAddPayment("Cash")
            this.addPaymentDialog.tenderedAmount = Math.abs(this.orderBalance)
            await this.addPaymentAndPrint()
            
            this.endShortcut()              
          }
          if(str ==='7'){
            if(!this.invoiceOK() || this.invoice.status !== 0){
              this.snack("Cannot perform action.")
              return
            }
            console.log("SLTP: Seal Order, Add Tender balance, Add Linx Payment and Print Receipt")
            
            this.chordingDialog.isLoading = true

            await this.sealOrder()
            this.openAddPayment("Linx")
            this.addPaymentDialog.tenderedAmount = Math.abs(this.orderBalance)
            await this.addPaymentAndPrint()
            
            this.endShortcut()              
          }
          if(str ==='0'){
            if(this.invoice.status != 0){
              this.snack("Cannot modify a sealed order.")
              return
            }
            let defaultCustomerId = parseInt(this.getGlobalValue("DEFAULT_CUSTOMER"))
            console.log("Set Default Customer")
            await this.setCustomer({id: defaultCustomerId})
            this.endShortcut()              
          }
        } catch (error) {
          console.log(error)
          this.snack(error)
        }
      },
      async handleQuickListEvent(code){
        try {
          let key = code.key.toLowerCase()
          console.log(key)
          let match = key.match(/\d/)
          if(match && match.length > 0){
            this.quickListDialog.columns[2] = parseInt(key)
            this.navigateIntoList(parseInt(key))
          }else if(key == 'backspace'){
            this.navigateOutOfList()
          }else if(key == 'arrowleft'){
            console.log("Focus Left")
            let newPos = this.quickListDialog.currentColumn-1
            if(newPos < 0){
              newPos = this.quickListDialog.columns.length-1
            }
            this.quickListDialog.currentColumn = newPos
          } else if(key == 'arrowright'){
            console.log("Focus Right")
            let newPos = this.quickListDialog.currentColumn+1
            if(newPos > this.quickListDialog.columns.length-1){
              newPos = 0
            }
            this.quickListDialog.currentColumn = newPos
          } else if(key == 'arrowdown'){
            let curr = this.quickListDialog.currentColumn
            let newPos = this.quickListDialog.columns[curr]+1

            if((curr == 0) && (newPos > this.quickListDialog.columnLengths[curr])){
              //directional list cycling mechanism for first column
              newPos = 0
            }
            if((curr == 1) && (newPos > this.quickListDialog.columnLengths[curr])){
              //directional list cycling mechanism for second column
              newPos = 0
            }
            if((curr == 2) && (newPos > this.quickListDialog.columnLengths[curr][this.quickListDialog.listItemOpen]-1)){
              //directional list cycling mechanism for third column
              newPos = 0
              console.log("end limit exceeded", newPos)
            }

            // console.log()
            this.quickListDialog.columns[curr] = newPos
            
            if(curr==1){
              this.navigateIntoList(newPos)
            }

            this.$forceUpdate()
          } else if(key == 'arrowup'){
            let curr = this.quickListDialog.currentColumn
            let newPos = this.quickListDialog.columns[curr] - 1

            if((curr == 0) && (newPos < 0)){
              //directional list cycling mechanism for first column
              newPos = this.quickListDialog.columnLengths[curr]
            }
            if((curr == 1) && (newPos < 0)){
              //directional list cycling mechanism for second column
              newPos = this.quickListDialog.columnLengths[curr]
            }
            if((curr == 2) && (newPos < 0)){
              //directional list cycling mechanism for third column
              console.log("0 limit exceeded")
              newPos = this.quickListDialog.columnLengths[curr][this.quickListDialog.listItemOpen]-1
            }

            this.quickListDialog.columns[curr] = newPos

            if(curr==1){
              this.navigateIntoList(newPos)
            }

            this.$forceUpdate()
          } else if(key =='enter'){
            if(this.quickListDialog.currentColumn == 0){
              this.addProductToInvoice(this.quickListDialog.data.favourites[this.quickListDialog.columns[0]].Product)
            }else if(this.quickListDialog.currentColumn == 2){
              this.addProductToInvoice(this.quickListDialog.data.lists[this.quickListDialog.listItemOpen].ProductCategoriesJoins[this.quickListDialog.columns[2]].Product)
            }
          }

        } catch (error) {
          console.log(error)
          this.snack(error)
        }
      },
      async attemptCreateInvoice(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/orders`, {createdBy: this.getId})
          if(res.data.error) throw res.data.error
          console.log(res.data)
          await this.$router.push({path: `/orders/view/${res.data.data.id}`})
        } catch (error) {
          console.log(error)
        }
      },
      openCloudPrintDialog(){
        this.cloudPrintDialog.isOpen = true
      },
      async printHandler(obj){ // {id, method, action}
      try {
        console.log("xdd",obj)
        this.printHandlerLoading = true
        let x = await axios[obj.method](`${this.getEndpoint}/${obj.action}/${obj.id}`)
        if(x.data.error) throw x.data.error
        
        let printWindow = open("","Printing")
        printWindow.document.write("")
        printWindow.document.write(x.data.data.job.htmlToPrint)
        
        printWindow.setTimeout(()=>{
          printWindow.print()
          printWindow.document.write("")
        },500)

      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      } finally {
        this.printHandlerLoading = false
      }
    },

      async directPrint(obj){
        try {
          this.directBigPrintLoading = true
          let x = await axios.post(`${this.getEndpoint}/api/print/preview/invoice/${obj.id}`)
          if(x.data.error) throw x.data.error
          console.log(x.data.data)
          let printWindow = open("","Printing")
          printWindow.document.write("")
          printWindow.document.write(x.data.data.job.htmlToPrint)
          printWindow.setTimeout(()=>{
            printWindow.print()
            printWindow.document.write("")
          },500)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.directBigPrintLoading = false
        }
      },

      async directPrintHandler(obj){
        try {
          this.directBigPrintLoading = true
          let x = await axios.post(`${this.getEndpoint}${obj.url}${obj.id}`)
          if(x.data.error) throw x.data.error
          console.log(x.data.data)
          let printWindow = open("","Printing")
          printWindow.document.write("")
          printWindow.document.write(x.data.data.job.htmlToPrint)
          printWindow.setTimeout(()=>{
            printWindow.print()
            printWindow.document.write("")
          },500)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.directBigPrintLoading = false
        }
      },

      async directPrintQuotation(obj){
        try {
          this.directBigPrintLoading = true
          let x = await axios.post(`${this.getEndpoint}/api/print/preview/quotation/${obj.id}`)
          if(x.data.error) throw x.data.error
          console.log(x.data.data)
          let printWindow = open("","Printing")
          printWindow.document.write("")
          printWindow.document.write(x.data.data.job.htmlToPrint)
          printWindow.setTimeout(()=>{
            printWindow.print()
            printWindow.document.write("")
          },500)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.directBigPrintLoading = false
        }
      },
      async directPrintStatement(obj){
        try {
          this.directStatementPrintLoading = true
          let x = await axios.post(`${this.getEndpoint}/api/print/preview/statement/${obj.id}`)
          if(x.data.error) throw x.data.error
          let printWindow = open("","Printing")
          printWindow.document.write("")
          printWindow.document.write(x.data.data.job.htmlToPrint)
          printWindow.setTimeout(()=>{
            printWindow.print()
            printWindow.document.write("")
          },500)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.directStatementPrintLoading = false
        }
      },
      async dynamicPrintShortcut(uri){
        try {
          this.directReceiptPrintLoading = true
          let y = uri.replace(":id1", this.invoice.id)
          let x = await axios.post(`${this.getEndpoint}/${y}`)
          if(x.data.error) throw x.data.error
          let printWindow = open("","Printing")
          printWindow.document.write("")
          printWindow.document.write(x.data.data.job.htmlToPrint)
          printWindow.setTimeout(()=>{
            printWindow.print()
            printWindow.document.write("")
            printWindow.close()
          },500)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.directReceiptPrintLoading = false
        }
      },
      async printCombinedReceipt(){
        try {
          this.combinedReceiptPrintLoading = true
          let x = await axios.post(`${this.getEndpoint}/api/print/invoiceWithCombinedReceiptsSmall/${this.invoice.id}`)
          if(x.data.error) throw x.data.error
          let printWindow = open("","Printing")
          printWindow.document.write("")
          printWindow.document.write(x.data.data.job.htmlToPrint)
          printWindow.setTimeout(()=>{
            printWindow.print()
            printWindow.document.write("")
            printWindow.close()
          },500)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.combinedReceiptPrintLoading = false
        }
      },
      async printReceiptBig(id){
        try {
          this.directReceiptPrintLoading = true
          let x = await axios.post(`${this.getEndpoint}/api/print/receiptOnly/${this.invoice.id}/${id}`)
          if(x.data.error) throw x.data.error
          console.log(x.data.data)
          let printWindow = open("","Printing")
          printWindow.document.write("")
          printWindow.document.write(x.data.data.job.htmlToPrint)
          printWindow.setTimeout(()=>{
            printWindow.print()
            printWindow.document.write("")
            printWindow.close()
          },500)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.directReceiptPrintLoading = false
        }
      },     
      async printPreview(type, metadata){
        try {
          this.printPreviewDialog.loading = true
          let x = await axios.post(`${this.getEndpoint}/api/print/preview/${type}/${metadata.id}`)
          if(x.data.error) throw x.data.error
          this.printPreviewDialog.data = x.data.data
          this.printPreviewDialog.loading = false
          this.printPreviewDialog.isOpen = true
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      closePrintPreviewDialog(){
        this.printPreviewDialog.loading = false
          this.printPreviewDialog.data = ''
          this.printPreviewDialog.isOpen = false
      },
      closePrintDialog(){
        this.cloudPrintDialog = {
          isOpen: false,
          quantity: 1,
          jobType: '',
          deliveryNote: '',
          printer: ''
        }
      },
      async attemptPrint(e){
        try {
            if(!e.pin&&!e.vsidToken) throw "Error Getting Credentials"
            e.metadata = this.cloudPrintDialog
            let documentId = (this.cloudPrintDialog.jobType === 'invoice'||this.cloudPrintDialog.jobType === 'jobTicket'?this.$route.params.id:this.cloudPrintDialog.deliveryNote)
            let res = await axios.post(`${this.getEndpoint}/api/print/${this.cloudPrintDialog.jobType}/${documentId}`, e)
            if(res.data.error) throw res.data.error
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.closePrintDialog();
        }
      },
      invoiceOK: function(){
        let verbose = false;
        if(verbose) console.log(this.invoice);
        //payment type ok?
        if(this.invoice && !this.invoice.metadata.paymentMethod) return false
        // if(this.invoice && this.invoice.metadata.paymentMethod == 'onAccount'){
        //   if(this.invoice.metadata && !this.invoice.metadata.arDownpayment) return false
        // }
        if(verbose) console.log("payment ok.")
        // customer ok?
        if(!this.invoice.customerId) return false
        if(verbose) console.log("customer ok.")
        //products ok?
        if(this.invoice.OrderLineItems && this.invoice.OrderLineItems.length == 0) return false
        for(let oli of this.invoice.OrderLineItems){
          if(oli.pending || oli.id==='adhoc' || oli.productId==='adhoc') return false;
        }
        if(this.invoice.grandTotal == 0) return false
        if(verbose) console.log("products ok.")
        //discountOk?
        // if(!this.discountOk) return false
        // if(verbose) console.log("discount ok.")
        //delivery info ok?
        if(!this.invoice.deliveryInfo.deliveryType) return false
        if(this.invoice.deliveryInfo.deliveryType === 'delivery'){
          if(!this.invoice.deliveryInfo.address) return false
        }
        if(verbose) console.log("delivery ok.")
        return true
      },
      async addSerialToItem(item){
        try {
          let res = await axios.put(`${this.getEndpoint}/api/orders/addSerialToInvoiceItem/${this.$route.params.id}/${item.id}`, {serial: item.serialField})
          if(res.data.error) throw res.data.error

          console.log(res.data.data)
          this.snack(`${this.serialNaming.singular} Added`)
          item.serials.push(res.data.data)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          item.serialField = ''
        }
      },
      updateLineItemDiscountType(i){
        let obj = {
          ...this.invoice.OrderLineItems[i],
          discountValue: 0,
          discountType: this.invoice.OrderLineItems[i].discountType===0?1:0
        }
        this.updateTotals()
        this.$set(this.invoice.OrderLineItems, i, obj)
        this.$forceUpdate();
      },
      updateLineItemCommissionType(i){
        let obj = {
          ...this.invoice.OrderLineItems[i],
          metadata: {
            ...this.invoice.OrderLineItems[i].metadata,
            commission: {
              ...this.invoice.OrderLineItems[i].commission,
              type:this.invoice.OrderLineItems[i].commission.type===0?1:0,
              value: 0
            }
          }
        }

        this.$set(this.invoice.OrderLineItems, i, obj)
        this.$forceUpdate();
      },
      // openCreditNotesDialog(){
      //   this.creditNotesDialog = true;
      // },
      // closeCreditNotesDialog(){
      //   this.creditNotesDialog = false;
      // },
      // addCreditNote(note){
      //   console.log(note);
      //   this.calcCreditNotesTotal()
      // },
      // removeCreditNote(i){
      //   this.invoice.metadata.creditNotes.splice(i, 1);
      //   this.calcCreditNotesTotal()
      // },
      // calcCreditNotesTotal(){
      //   if(this.invoice.metadata.creditNotes.length===0){
      //     this.invoice.metadata.creditNotesTotal = 0;
      //     return;
      //   }
      //   let total = 0;
      //   this.invoice.metadata.creditNotes.forEach(note => {
      //     total+=note.amount;
      //   });
      //
      //   this.invoice.metadata.creditNotesTotal = total;
      // },
      rowClick(row){
        this.$router.push({path: `/deliveries/view/${row.id}`})
      },
      parseDeliveryStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Delivered"
      },
      parseOrderStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Admin Sealed"
        else if(id===3) return "Delivery Scheduled"
        else if(id===4) return "Pending Reschedule"
        else if(id===5) return "Out For Delivery"
        else if(id===6) return "Delivered"
      },
      applyDefaultCommissionToAll(){
        this.invoice.OrderLineItems.forEach(item=>{item.metadata.commission.type = 0; item.metadata.commission.value = this.csrUser.metadata.commission})
        this.snack("✅ Line commissions updated to CSR default of "+ this.csrUser.metadata.commission + "%.")
      },
      clearScanString(){
        this.scanString = ''
        this.scanInProgress = false
        console.log("🔁 Scan String Cleared")
      },
      clearScanStringTimeout(){
        this.timeout = setTimeout(this.clearScanString,500)
      },
      cancelClearScanStringTimeout(){
        clearTimeout(this.timeout)
      },
      activateScanSense(){ // deprecated 
        console.log('ORDER PAGE: ✅ Scan Sense Active.')
        window.addEventListener('keydown', (e)=>{ 
          if(this.getUser && this.scanBus.locked && this.scanBus.type == "order"){ 
            console.log('ORDER')
            this.cancelClearScanStringTimeout()
            this.clearScanStringTimeout()
            if(this.chordingDialog.isOpen){
              this.cancelClearScanStringTimeout()
              if(e.key==='/'){
                this.endShortcut()
              }else{
                this.handleChord(e)
              }
            }else{
              if(e.key==='/'&&this.scanString!="\\"&&this.scanString!="\\/"){           
                this.cancelClearScanStringTimeout()
                this.chordingDialog.isOpen = true 
              }else if(e.key==='Enter'){
                let validatedType = scanSystem.validateType(this.scanString)
                if(validatedType){
                  this.scanInProgress = true
                  console.log('Flag')
                  this.handleScan(validatedType)
                  this.clearScanString
                }else{
                  this.clearScanString
                }
              }else{
                this.scanInProgress = false
                if(!this.scanString) this.scanString = ""
                if(e.key!='Shift'&&e.key!='LeftAlt'){
                  this.scanString = this.scanString + e.key
                }
              }
            }
          }
        })  
      },
      async handleScan(type){
        try {
          let s = this.scanString.replace(/\\\/\/\\=/,'')
          let pId = (s.replace(`${type}-`, ''));
          let p;
          pId
          let prod = null
          switch(type){
            case 'INV':
              console.log("Invoice Scanned: ", s)
              p = `/orders/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              break
            case 'VSID':
              // this.setScanBus({username: this.lookupUsername(pId.split("-")[0]), uId: pId.split("-")[0]})
              // console.log(this.lookupUsername(pId.split("-")[0]))
              p = '/users/view/'+pId.split("-")[0]
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              break
            case 'JT':
              console.log("Job Ticket Scanned: ", s)
              p = `/orders/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              break
            case 'DN':
              console.log("Delivery Note Scanned: ", s)
              p = `/deliveries/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              break
            case 'PL':
              console.log("Product Scanned: ", s) //this
              prod = await this.lookupProduct((s.split('-')[1]), "ID")
              if(!prod) throw "Product Not In VIMS DB."
              this.addProductToInvoice(prod.data)
              break
            case 'EXT':
              console.log("Invoice External Product Scanned: ", s)
              prod = await this.lookupProduct(s, "SKU")
              if(!prod) throw "External Product Not In VIMS DB."
              this.addProductToInvoice(prod)
              this.updateInvoice()
              break
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          console.log("Done")
          this.clearScanString()
        }
      },
      openProductScanDialog(){
        this.productScanDialog.isOpen = true
        this.productScanDialog.loader = true
      },
      closeProductScanDialog(){
        this.productScanDialog.isOpen = false,
        this.productScanDialog.loader = false
        this.productScanDialog.item = ''
      },
      async lookupProduct(s, type){
        try {
          console.log(s)
          let res;
          if(type === "SKU"){
            res = await axios.get(`${this.getEndpoint}/api/products/bySKU/${s}`)
            if(res.data.error) throw res.data.error
            if(!res.data.data) throw 'SKU barcode is not in DB.'
          }
          if(type === "PL"){
            res = await axios.get(`${this.getEndpoint}/api/products/${s}`)
            if(res.data.error) throw res.data.error
            if(!res.data.data) throw 'PL barcode error.'
          }
          return res.data.data
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.productScanDialog.loader = false
        }
      },
      async openAddPayment(type){
        try{
          this.addPaymentLoading = true;

          if(this.getGlobalValue('registerModuleEnabled')!=='true'){
            this.openAddPaymentConfirmed(type)
            return;
          }

          this.paymentToConfirmType = type;
          let res = await axios.get(`${this.getEndpoint}/api/registers/checkPaymentBypass`)
          if(res.data.error) throw res.data.error
          if(!res.data.data.prompt){
            this.openAddPaymentConfirmed(type)
            return;
          }
          this.confirmAddPaymentDialog.isOpen = true;
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.addPaymentLoading = false
        }
      },
      cancelAddPaymentConfirm(){
        this.paymentToConfirmType = "";
        this.confirmAddPaymentDialog.isOpen = false;
      },
      confirmAddPayment(){
        this.addPaymentDialog.bypass = true;
        this.openAddPaymentConfirmed(this.paymentToConfirmType)

        this.cancelAddPaymentConfirm()
      },
      openAddPaymentConfirmed(type){
        if(type){
          let x = this.lookupPaymentTypeId(type)
          if(x)
            this.addPaymentDialog.selectedType = x
        }
        this.addPaymentDialog.isOpen = true
      },
      closeAddPaymentDialog(){
        this.addPaymentDialog.isOpen = false
        this.addPaymentDialog.selectedType = null
        this.addPaymentDialog.tenderedAmount = null
        this.addPaymentDialog.chequeNumber = null
        this.addPaymentDialog.creditNote = null
        this.addPaymentDialog.cnMessage = null
        this.addPaymentDialog.notes = ""
        this.addPaymentDialog.bypass = false
      },
      async addPayment(){
        try {
          this.addPaymentDialog.loading = true
          let obj = {
            orderId: this.$route.params.id,
            tenderedAmount: this.addPaymentDialog.tenderedAmount,
            paymentType: this.addPaymentDialog.selectedType,
            metadata: {
              chequeNumber: this.addPaymentDialog.chequeNumber,
              creditNotes: this.addPaymentDialog.creditNote,
              pitBalance: this.orderBalance
            },
            notes: this.addPaymentDialog.notes,
            bypass: this.addPaymentDialog.bypass
          }

          let res = await axios.post(`${this.getEndpoint}/api/orders/addPayment/${this.$route.params.id}`, obj)
          if(res.data.error) throw res.data.error
          this.snack("Order Added")
          this.closeAddPaymentDialog()
          this.snack("Payment Successfully Added.")
          this.paymentTable.items.push(res.data.data)
        } catch (error) {
          console.log(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.addPaymentDialog.loading = false
          this.addPaymentDialog.bypass = false
        }
      },
      async  addPaymentAndPrint(){
        try {
          this.addPaymentDialog.loading = true
          let obj = {
            orderId: this.$route.params.id,
            tenderedAmount: this.addPaymentDialog.tenderedAmount,
            paymentType: this.addPaymentDialog.selectedType,
            metadata: {
              chequeNumber: this.addPaymentDialog.chequeNumber,
              creditNotes: this.addPaymentDialog.creditNote,
            }
          }
          let res = await axios.post(`${this.getEndpoint}/api/orders/addPayment/${this.$route.params.id}`, obj)
          if(res.data.error) throw res.data.error
          this.snack("Order Added")
          this.closeAddPaymentDialog()
          this.snack("Payment Successfully Added.")
          this.paymentTable.items.push(res.data.data)
          await this.dynamicPrintShortcut(this.getGlobalValue("VEC_ORDER_SHORTCUT_PRINT_RECEIPT_URI"))
        } catch (error) {
          this.snack(error)
          console.log(error)
        } finally {
          this.addPaymentDialog.loading = false
        }
      },
      paymentTableRowClick(e){
        this.viewPaymentDialog.paymentToDisplay = this.paymentTable.items.filter(x=>x.id==e)[0]
        this.viewPaymentDialog.isOpen = true
        console.log(this.viewPaymentDialog)
      },
      closeViewPaymentDialog(){
        this.viewPaymentDialog.isOpen = false
        this.viewPaymentDialog.paymentToDisplay = {}
      },
      updateSelectedCreditNote(){
        let x = parseInt(this.addPaymentDialog.creditNote)
        let y = this.addPaymentDialog.creditNoteAvailable.findIndex(v=>v.id == x)
        console.log(x,y)
        
        let cnAmount = parseFloat(this.addPaymentDialog.creditNoteAvailable[y].amount)
        let bal = Math.abs(this.orderBalance)
        console.log(bal, cnAmount)
        
        if(bal == cnAmount){
          this.addPaymentDialog.cnMessage = null
          this.addPaymentDialog.tenderedAmount = parseFloat(cnAmount)
        } else if(bal > cnAmount){
          this.addPaymentDialog.cnMessage = "The Balance exceeds the CN amount."
          this.addPaymentDialog.tenderedAmount = parseFloat(cnAmount)
        }else{
          this.addPaymentDialog.cnMessage = "The Value of the selected CN exceeds the balance. A new CN will be created with the Customer's Balance."
          this.addPaymentDialog.tenderedAmount = parseFloat(bal)
        }
        
      },
      // runPaymentDialogValidations(){
      //   this.addPaymentDialog.cnMessage = null
      //   if(this.addPaymentDialog.selectedType&&this.addPaymentDialog.tenderedAmount){
      //     if(this.lookupPaymentType(this.addPaymentDialog.selectedType)=='Credit Note'){

      //     }else {
      //       this.addPaymentDialog.cnMessage = null
      //       this.addPaymentDialog.creditNote = []
      //     }
      //   }
      // },
      async createNewReturn(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/`)
          if(res.data.error) throw res.data.error
          this.createDialog.isOpen = false
          await this.$router.push({ path: `/${this.pluralLower}/create/${res.data.data.id}`})
        } catch (error) {
          console.log(error)
          this.snack(error.msg || error.msg?.message || error, "error");
         this.createDialog.isOpen = false
        }
      },
      async updateInvoiceDatePaid(){
        try {
          this.syncStatus = 0
          let valToSend = this.invoice.metadata?.csrPaidAt
          if(!this.invoice.metadata?.csrPaidAt) valToSend = -1
          let res = await axios.put(`${this.getEndpoint}/api/orders/commissionInfo/${this.$route.params.id}`, {csrPaidAt: valToSend})
          if(res.data.error) throw res.data.error  
          this.snack('Commission info updated')
          this.syncStatus = 1
        } catch (error) {
          this.syncStatus = 2
          console.log(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async updateInvoiceCommissionRate(){
        try {
          this.syncStatus = 0
          let valToSend = this.invoice.metadata?.commissionRate
          if(!this.invoice.metadata?.commissionRate) valToSend = -1
          let res = await axios.put(`${this.getEndpoint}/api/orders/commissionInfo/${this.$route.params.id}`, {commissionRate: valToSend})
          if(res.data.error) throw res.data.error  
          this.snack('Commission info updated')
          this.syncStatus = 1
        } catch (error) {
          this.syncStatus = 0
          console.log(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async showLineItemProductImage(item){
        try {
          this.syncStatus = 0
          this.productImageDialog.loading = true
          let res = await axios.get(`${this.getEndpoint}/api/products/featuredImage/${item.productId}`)
          if(res.data.error) throw res.data.error
          if(!res.data.data) throw "No image available."
          this.productImageDialog = {
            isOpen: true,
            source: res.data.data,
            name: item.name
          }
          this.syncStatus = 1
        } catch (error) {
          this.syncStatus = 0
          console.log(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.productImageDialog.loading = false
        }
      },   
      closeProductImageDialog(){
        this.productImageDialog = {
            isOpen: false,
            loading: false,
            source: '',
            name: ''
          }
      },
      openSearchDialog(){
        this.search.dialog=true;
      },
      clearSearch(){
        this.search.value = "";
        this.search.results = [];
      },
      async querySearch(){
        try{
          this.search.loading = true;
          this.search.results = [];

          if(!this.search.value) return;

          let searchData = {
            val: this.search.value
          }

          let uriFields = Object.keys(searchData).map(x => {
            return x + "=" + (searchData[x]!==undefined?encodeURIComponent(searchData[x]):'')
          }).join("&");

          let res;
          let arr = [];
          switch (this.search.type) {
            case "Customers":
              res = await axios.get(`${this.getEndpoint}/api/customers/search?${uriFields}`);
              if(res.data.error) throw res.data.error;
              arr = res.data.data;
              break;
            case "Products":
            default:
              res = await axios.get(`${this.getEndpoint}/api/products/searchByAllVariationsNoLimit?${uriFields}`);
              if(res.data.error) throw res.data.error
              arr = res.data.data;
          }

          this.search.results = arr;
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.search.loading = false;
        }
      },
      async updateOrderCreditCardCharges(){
        try {
          if(this.invoice?.metadata?.addCreditCardCharges == false){
            this.invoice.metadata.creditCardChargesRate = 0
          }else if(this.invoice?.metadata?.addCreditCardCharges == true){
            console.log("Header")
            if(this.invoice.metadata.creditCardChargesRate < 0.0 || this.invoice.metadata.creditCardChargesRate > parseFloat(this.getGlobalValue('VEC_MAX_CC_RATE'))){
              this.invoice.metadata.creditCardChargesRate = 0
              this.snack("Charges out of bound. Resetting to 0.")
            }
          }
          await this.updateInvoice()
        } catch (error) {
          console.log(error)
            this.snack(error)
        }
      }
    }
  }
</script>